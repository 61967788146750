var API = 'https://localhost.api';
var LIVE_API = 'https://localhost.live-api';
var SECURITY_API = 'https://localhost.security-api';
var HUB = {
  purchases: 'https://localhost.'
};
var HOTPAY = {
  checkoutUrl: 'https://localhost.'
};
var VULCANO = {
  dashboard: 'https://localhost.'
};
var PLAYER = {
  embed: 'https://localhost.'
};
var CAS = {
  signup: 'https://localhost.'
};
var LIVE = {
  admin: 'https://https://localhost.live-admin',
  user: 'https://https://localhost.live-user',
  manager: 'https://https://localhost.live-manager',
  API: LIVE_API,
  APIv1: "".concat(LIVE_API, "/v1")
};
var SECURITY = {
  API: SECURITY_API,
  APIv1: "".concat(SECURITY_API, "/api/v1")
};
var endpoints = {
  API: API,
  LIVE: LIVE,
  HUB: HUB,
  HOTPAY: HOTPAY,
  VULCANO: VULCANO,
  PLAYER: PLAYER,
  CAS: CAS,
  SECURITY: SECURITY,
  APIv1: API + '/rest/v1',
  APIv2: API + '/rest/v2',
  APIv3: API + '/rest/v3',
  APP: 'https://localhost.app',
  FORGOT_PASSWORD: 'https://localhost.forgot-password'
};
var cookie = {
  domain: 'buildstaging.com',
  subdomain: 'sparkle-app'
};
var s3 = {
  region: 'us-test-1',
  cognitoRegion: 'us-test-1',
  apiVersion: '2006-03-01',
  Bucket: 'staging-content.sparkle',
  IdentityPoolId: 'us-test-1:11897e12-765d-4f58-a739-554d35dcec77'
};
var HOTJAR_ID = 2560537;
module.exports = {
  cookie: cookie,
  endpoints: endpoints,
  s3: s3,
  HOTJAR_ID: HOTJAR_ID
};
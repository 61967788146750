export var basePath = '/communities';
export var RoutesPaths = {
  HOME: "".concat(basePath),
  PUBLISH: "".concat(basePath, "/publish"),
  LOGIN: "".concat(basePath, "/login"),
  LOGOUT: "".concat(basePath, "/logout"),
  ME: "".concat(basePath, "/me"),
  PROFILE: "".concat(basePath, "/u"),
  BONUS: "".concat(basePath, "/b"),
  SPARK: "".concat(basePath, "/s"),
  TRACK: "".concat(basePath, "/t"),
  SPARK_EMBED: "".concat(basePath, "/ep"),
  TRACK_EMBED: "".concat(basePath, "/et"),
  FAQ: "".concat(basePath, "/faq"),
  INCORPORATE: "".concat(basePath, "/se"),
  LIVE: "".concat(basePath, "/live"),
  AUTH_LOGIN: "".concat(basePath, "/auth/login"),
  AUTH_LOGOUT: "".concat(basePath, "/auth/logout"),
  AUTH_RENEW: "".concat(basePath, "/auth/renew"),
  AUTH_UNAUTHENTICATED: "".concat(basePath, "/auth/unauthenticated"),
  TOS: "".concat(basePath, "/tos"),
  PRIVACY: "".concat(basePath, "/privacy"),
  COOKIES: "".concat(basePath, "/cookies"),
  DISCOVER: "".concat(basePath, "/discover"),
  TRENDING: "".concat(basePath, "/trending"),
  CODE: "".concat(basePath, "/code"),
  CARD_TOS: "".concat(basePath, "/cardTos"),
  CARD_PRIVACY: "".concat(basePath, "/cardPrivacy"),
  CARD_POINTS: "".concat(basePath, "/cardPoints"),
  ERROR: '/_error'
};
import * as comments from "./comments";
import * as donation from "./donation";
import * as session from "./session";
import * as sparks from "./sparks";
import * as thread from "./thread";
import * as tracks from "./tracks";
import * as uploads from "./uploads";
import * as users from "./users";
import * as subscribed from "./subscribed";
import * as auth from "./auth";
import liveManager from "./live/manager";
export var reducers = {
  comments: comments.reducer,
  donation: donation.reducer,
  session: session.reducer,
  sparks: sparks.reducer,
  thread: thread.reducer,
  tracks: tracks.reducer,
  uploads: uploads.reducer,
  users: users.reducer,
  subscribed: subscribed.reducer,
  auth: auth.reducer,
  lives: liveManager.reducer
};
export var initialStateModel = {
  comments: comments.initialState,
  donation: donation.initialState,
  session: session.initialState,
  sparks: sparks.initialState,
  thread: thread.initialState,
  tracks: tracks.initialState,
  uploads: uploads.initialState,
  users: users.initialState,
  subscribed: subscribed.initialState,
  auth: auth.initialState,
  lives: liveManager.initialState
};
import Auth from "./Auth";
export var hasBirthDate = function hasBirthDate(user) {
  var birthDate = user.birthDate;
  return typeof birthDate !== 'undefined' && birthDate !== null && birthDate !== '';
};
export var hasUserMinimumLegalAge = function hasUserMinimumLegalAge() {
  var minimumLegalAge = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 18;
  var user = Auth.getUser();
  if (user && hasBirthDate(user)) {
    return getAge(user.birthDate) >= minimumLegalAge;
  }
  return false;
};

// Most readable method
export var calculateAge = function calculateAge(date) {
  if (!Date.now) {
    Date.now = function () {
      return new Date().getTime();
    };
  }
  var birthDate = new Date(date);
  var ageDifMs = Date.now() - birthDate.getTime();
  var ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

// Most accurate method
export var getAge = function getAge(date) {
  var today = new Date();
  var birthDate = new Date(date);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || m === 0 && today.getDate() < birthDate.getDate()) {
    age--;
  }
  return age;
};
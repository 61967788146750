import _defineProperty from "/drone/src/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
var _createReducer;
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import Auth from "../utils/Auth";
import { createReducer } from "../utils/redux";
export var initialState = {
  user: null,
  fetching: false,
  fetched: false,
  error: null
};
export var types = {
  FETCH: 'session/FETCH',
  FETCH_FULFILLED: 'session/FETCH_FULFILLED',
  FETCH_REJECTED: 'session/FETCH_REJECTED',
  FETCH_PENDING: 'session/FETCH_PENDING',
  SET_USER_PROP: 'session/SET_USER_PROP'
};

// REDUCERS
var pending = function pending(state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    fetching: true,
    fetched: false,
    error: null
  });
};
var fulfilled = function fulfilled(state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    user: action.payload.user,
    fetching: false,
    fetched: true
  });
};
var rejected = function rejected(state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    user: null,
    fetching: false,
    fetched: false,
    error: action.payload
  });
};
var setProp = function setProp(state, action) {
  var _action$payload = action.payload,
    propName = _action$payload.propName,
    propValue = _action$payload.propValue;
  var user = state.user;
  if (user) {
    user[propName] = propValue;
  }
  return _objectSpread(_objectSpread({}, state), {}, {
    user: _objectSpread({}, user)
  });
};
export var reducer = createReducer(initialState, (_createReducer = {}, _defineProperty(_createReducer, types.FETCH_PENDING, pending), _defineProperty(_createReducer, types.FETCH_FULFILLED, fulfilled), _defineProperty(_createReducer, types.FETCH_REJECTED, rejected), _defineProperty(_createReducer, types.SET_USER_PROP, setProp), _createReducer));

// ACTIONS
export var setUser = function setUser(user, req) {
  return function (dispatch) {
    Auth.setUser(user, req);
    dispatch({
      type: types.FETCH_FULFILLED,
      payload: {
        user: user
      }
    });
  };
};
export var removeUser = function removeUser() {
  return function (dispatch) {
    dispatch({
      type: types.FETCH_FULFILLED,
      payload: {
        user: null
      }
    });
  };
};
export var fetchUser = function fetchUser(req) {
  return function (dispatch) {
    var prom = Auth.fetchUser(req);
    if (dispatch) {
      dispatch({
        type: types.FETCH_PENDING
      });
      prom.then(function (user) {
        setUser(user, req)(dispatch);
      }).catch(function (err) {
        dispatch({
          type: types.FETCH_REJECTED,
          payload: err.response || err
        });
        throw err;
      });
    }
    return prom;
  };
};
export var setUserProp = function setUserProp(propName, propValue) {
  return function (dispatch) {
    dispatch({
      type: types.SET_USER_PROP,
      payload: {
        propName: propName,
        propValue: propValue
      }
    });
  };
};
import Event from "./";
import Router from 'next/router';
export var eventTypes = ['url', 'tap', 'doubletap', 'taphold', 'swipe', 'scroll', 'viewload', 'viewunload', 'orientationchange'];
export var pageView = function pageView() {
  var pageTitle = Router.router && Router.router.route;
  Event.track({
    name: 'page_view',
    supportedDestination: ['datahub', 'firebase', 'googleanalytics'],
    type: 6,
    attributes: {
      pageTitle: pageTitle,
      pagePath: window.location.pathname
    }
  });
};
export var transformMsToSeconds = function transformMsToSeconds(ms) {
  var seconds = ms / 1000;
  return seconds.toFixed(2);
};
import _defineProperty from "/drone/src/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import CookieParser from 'cookie';
import { cookie } from "./config";
export var defaultOptions = {
  path: '/',
  domain: cookie.domain
};
export var cookieList = {
  SP_AT: 'hmVlcIntegration',
  SP_RT: 'sp_rt',
  SP_ME: 'sp_me',
  HOT_ID: 'hotid'
};
export var getAll = function getAll(req) {
  var cookies = null;
  if (req) {
    cookies = req.headers.cookie;
  } else if (process.browser) {
    cookies = document.cookie;
  }
  return parse(cookies);
};
export var get = function get(key, req) {
  var cookies = getAll(req);
  return key ? cookies[key] : cookies;
};
export var set = function set(key, value, options, res) {
  var cookieOptions = _objectSpread(_objectSpread({}, defaultOptions), options);
  if (res) {
    res.cookie(key, value, cookieOptions);
  } else if (process.browser) {
    document.cookie = CookieParser.serialize(key, value, cookieOptions);
  }
  var newCookies = getAll(res);
  return newCookies[key];
};
export var remove = function remove(key, res, options) {
  if (res) {
    res && res.clearCookie(key, _objectSpread(_objectSpread({}, defaultOptions), options));
  } else if (process.browser) {
    document.cookie = "".concat(key, "=; path=/; domain=").concat(options.domain, "; expires=Thu, 01 Jan 1970 00:00:01 GMT;");
  }
};
export var parse = function parse(str) {
  var valid = str && typeof str === 'string';
  return valid ? CookieParser.parse(str) : {};
};
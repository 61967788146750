var KEYS = ['fetching', 'fetched', 'error'];
export var createStatusProps = function createStatusProps(status) {
  var props = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var keys = Object.keys(props) || [];
  return keys.reduce(function (acc, key) {
    acc["".concat(key).concat(status)] = props[key];
    return acc;
  }, {});
};
export var getStatusProps = function getStatusProps(state, status) {
  return KEYS.reduce(function (acc, key) {
    acc[key] = state["".concat(key).concat(status)];
    return acc;
  }, {});
};
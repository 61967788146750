import { endpoints } from "../config";
import { authMediaUrl } from "../url";
import Auth from "../Auth";
export var getMedia = function getMedia(trackId, secure) {
  var size = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  if (!trackId) return null;
  var isAuth = secure && Auth.isLogged();
  var authPath = isAuth ? 'image' : 'imagePublic';
  var dimensions = size > 0 ? "dimensions=".concat(size, "x").concat(size) : '';
  var path = "".concat(endpoints.APIv1, "/collections/").concat(trackId, "/").concat(authPath, "?").concat(dimensions);
  return isAuth ? authMediaUrl(path) : path;
};
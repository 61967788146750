var _this = this;
import { getParam } from "./remote-config";
import { getCurrentLang } from "./i18n";
import { endpoints, cookie } from "./config";
import { RoutesPaths } from "../../shared/constants/RoutesPaths";
var isRemoteConfigEnabled = function isRemoteConfigEnabled() {
  var param = getParam('enable_hotmart_lgpd');
  if (typeof param !== 'boolean') {
    var _parsedParam = JSON.parse(param);
    if (Array.isArray(_parsedParam)) {
      var _currentLang = getCurrentLang();
      return _currentLang && _parsedParam.indexOf(_currentLang) >= 0;
    }
    return false;
  }
  return param;
};
export var loadHotmartLgpdScript = function loadHotmartLgpdScript() {
  if (isRemoteConfigEnabled()) {
    var _script = document.createElement('script');
    _script.type = 'text/javascript';
    _script.src = 'https://static.hotmart.com/hot-lgpd/lgdpscript.min.js';
    _script.async = true;
    document.body.appendChild(_script);

    // Resolve the promise once the script is loaded
    _script.addEventListener('load', function () {
      window.hotLGPD.config = {
        manualInitializing: false,
        cookieDomain: "".concat(cookie.subdomain, ".").concat(cookie.domain),
        originDomainsRegex: /^https?:\/\/([-a-zA-Z0-9._]*\.)?(buildstaging\.com|hotmart\.com(:[0-9]{4})?)(\/)?/,
        langCookieName: 'next-i18next'
      };
      window.hotLGPD.contentMessages = {
        pt: {
          message: "Atualizamos nossa Pol\xEDtica de Privacidade, <a class=\"hot-lgpd-box__link\" href=\"".concat(endpoints.APP, "/").concat(getCurrentLang()).concat(RoutesPaths.PRIVACY, "\" rel=\"noopener noreferrer nofollow\" target=\"_blank\">clique aqui</a> e acesse."),
          link: 'Fechar'
        },
        en: {
          message: "We've updated our Privacy Policy, <a class=\"hot-lgpd-box__link\" href=\"".concat(endpoints.APP, "/").concat(getCurrentLang()).concat(RoutesPaths.PRIVACY, "\" rel=\"noopener noreferrer nofollow\" target=\"_blank\">click here</a> to read it."),
          link: 'Close'
        },
        es: {
          message: "Actualizamos nuestra Pol\xEDtica de Privacidad, <a class=\"hot-lgpd-box__link\" href=\"".concat(endpoints.APP, "/").concat(getCurrentLang()).concat(RoutesPaths.PRIVACY, "\" rel=\"noopener noreferrer nofollow\" target=\"_blank\">haz clic aqu\xED</a> para acceder."),
          link: 'Cerrar'
        }
      };
    });

    // Catch any errors while loading the script
    _script.addEventListener('error', function () {
      console.error("".concat(_this.src, " failed to load."));
    });
  }
};
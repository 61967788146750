var _defineProperty = require("/drone/src/node_modules/next/node_modules/@babel/runtime/helpers/defineProperty");
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
var ENV = process.env.APP_ENV || 'production';
var config = require('./' + ENV);
var SCRAPER = {
  api_key: process.env.SCRAPER_API_KEY,
  api_path: 'http://api.scraperapi.com'
};
module.exports = _objectSpread(_objectSpread({}, config), {}, {
  SCRAPER: SCRAPER
});
export var MIN_COLUMNS = 2;
export var MAX_COLUMNS = 4;
export var MASONRY_GAP = 24;
export var DEFAULT_CARD_HEIGHT = 300;
export var DEFAULT_CARD_WIDTH = 240;
export var MIN_CARD_WIDTH = 120;
export var TITLE_LINE_HEIGHT = 19;
export var TITLE_CHARS_PER_LINE = 27;
export var TITLE_MARGIN_TOP = 8;
export var MAX_TITLE_LINES = 4;
export var TRACK_LINE_HEIGHT = 16;
export var TRACK_CHARS_PER_LINE = 30;
export var TRACK_MARGIN_TOP = 4;
export var MAX_TRACK_LINES = 3;
export var MARGINS_TOTAL = MASONRY_GAP + TITLE_MARGIN_TOP + TRACK_MARGIN_TOP;
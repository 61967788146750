import styled, { css } from 'styled-components';
import { colors, breakpoints } from "../../styles/variables";
export var NavWrapper = styled.div.withConfig({
  displayName: "wrappers__NavWrapper",
  componentId: "sc-1yiezcv-0"
})(["@media (min-width:", "){border-radius:50%;> a,> button,> div > button{&:first-of-type{display:block;border-radius:50%;width:48px;height:48px;display:flex;align-items:center;justify-content:center;}}}"], breakpoints.md);
export var HighlightedWrapper = styled(NavWrapper).withConfig({
  displayName: "wrappers__HighlightedWrapper",
  componentId: "sc-1yiezcv-1"
})(["svg{width:28px;height:28px;}> a,> button,> div > button{&:first-of-type{path,circle,rect{stroke:", "}", "}}@media (min-width:", "){", " svg{width:24px;height:24px;}> a,> button,> div > button{&:first-of-type{&:hover,&:active,&:focus{path,circle,rect{stroke:", "}}}}}"], colors.gray80, function (props) {
  return props.isCurrentPage && css(["path,circle,rect{stroke:", "}"], colors.primary);
}, breakpoints.md, function (props) {
  return props.isCurrentPage && css(["background-color:", ";"], "".concat(colors.primary, "40"));
}, colors.primary);
import { DEV_ENV, ENV } from "./config";
import REMOTE_CONFIG from "../../../remote-config.json";
var DEFAULT_CONDITION = 'web_' + (DEV_ENV || ENV);
var parseValue = function parseValue(value) {
  if (value === 'yes' || value === 'true') {
    return true;
  }
  if (value === 'no' || value === 'false') {
    return false;
  }
  return value;
};
var getValueByCondition = function getValueByCondition(param, condition) {
  var value = param.conditionalValues[condition].value;
  return value;
};
var getParamValue = function getParamValue(param) {
  if (!param.defaultValue) return null;
  var value = param.defaultValue.value;
  return value;
};
var parseParam = function parseParam(param, condition) {
  var isConditional = Boolean(condition && param.conditionalValues && param.conditionalValues[condition]);
  var value = isConditional ? getValueByCondition(param, condition) : getParamValue(param);
  return parseValue(value);
};
var getWebParam = function getWebParam(paramName) {
  var params = getParams();
  var param = params[paramName + '_web'] || params[paramName];
  return param;
};
export var getParam = function getParam(paramName) {
  var condition = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DEFAULT_CONDITION;
  var param = getWebParam(paramName);
  if (!param) return null;
  var parsed = parseParam(param, condition);
  return parsed;
};
export var getParams = function getParams() {
  return REMOTE_CONFIG.parameters;
};
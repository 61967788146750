import _defineProperty from "/drone/src/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import Event from "./event";
import { types } from "../ducks/uploads";
import { getOrCreateStore } from "../with-redux-store";
import { transformMsToSeconds } from "./event/helper";
var dispatcher = function dispatcher(type, payload) {
  var store = getOrCreateStore();
  return store.dispatch({
    type: type,
    payload: payload
  });
};
var runCallback = function runCallback(callbackName, fileId, data) {
  var store = getOrCreateStore();
  var state = store.getState().uploads;
  var callbacks = state.byId[fileId].callbacks;
  var fn = callbacks && callbacks[callbackName];
  if (fn && typeof fn === 'function') {
    fn(_objectSpread({
      fileId: fileId
    }, data));
  }
};
var sendEvent = function sendEvent(eventName, fileId, data) {
  var store = getOrCreateStore();
  var state = store.getState();
  var uploads = state.uploads,
    session = state.session;
  var upload = uploads.byId[fileId];
  var timeSpent = upload.startDate && upload.endDate && transformMsToSeconds(upload.endDate - upload.startDate);
  var uploadRate = timeSpent && upload.loaded / timeSpent;
  var eventProps = {
    timeSpent: timeSpent,
    uploadRate: uploadRate,
    context: upload.context,
    name: upload.name,
    type: upload.type,
    size: upload.size,
    loaded: upload.loaded,
    total: upload.total,
    progress: upload.progress,
    userId: session.user && session.user.id,
    message: upload.error && upload.error.message
  };
  Event.track({
    name: eventName,
    supportedDestination: ['datahub', 'firebase'],
    type: 6,
    attributes: eventProps
  });
};
export var onUploadStart = function onUploadStart(fileId, file, callbacks, context) {
  dispatcher(types.START, {
    fileId: fileId,
    file: file,
    callbacks: callbacks,
    context: context
  });
  runCallback('onStart', fileId, file);
  sendEvent('upload_media_call', fileId, file);
};
export var onUploadProgress = function onUploadProgress(fileId, data) {
  dispatcher(types.PROGRESS, {
    fileId: fileId,
    data: data
  });
  runCallback('onProgress', fileId, data);
};
export var onUploadSuccess = function onUploadSuccess(fileId, data) {
  dispatcher(types.SUCCESS, {
    fileId: fileId,
    data: data
  });
  runCallback('onSuccess', fileId, data);
  sendEvent('upload_media_success', fileId, data);
};
export var onUploadFailure = function onUploadFailure(fileId, data) {
  dispatcher(types.FAILURE, {
    fileId: fileId,
    data: data
  });
  runCallback('onFailure', fileId, data);
  sendEvent('upload_media_error', fileId, data);
};
import themes from "./themes";
import { getParam } from "../../state/utils/remote-config";
export var themeVersion = getParam('theme_version');
var currentTheme = themes[themeVersion] || themes['2.0'];
export var colors = currentTheme.colors;
export var fonts = currentTheme.fonts;
export var cardShadow = currentTheme.cardShadow || "";
export var breakpoints = {
  xl: 1360 + 'px',
  lg: 992 + 'px',
  md: 766 + 'px',
  sm: 576 + 'px',
  xs: 380 + 'px'
};
export var fullHeight = "\n  min-height: calc(100vh - 60px - 120px);\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n";
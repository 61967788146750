import { i18n } from "./i18next";
var DEFAULT_LANGUAGE = 'pt';
export var LANGUAGES = {
  'pt': 'pt-br',
  'pt-BR': 'pt-br',
  'pt-br': 'pt-br',
  'en': 'en',
  'en-US': 'en',
  'es': 'es',
  'es-ES': 'es'
  // 'fr': 'fr',
  // 'fr-FR': 'fr'
};

export var getCurrentLang = function getCurrentLang() {
  return LANGUAGES[i18n.language] || DEFAULT_LANGUAGE;
};
export var setLang = function setLang(lang) {
  var language = LANGUAGES[lang] || DEFAULT_LANGUAGE;

  // Change the lang html attribute
  if (typeof window !== 'undefined') {
    document.getElementsByTagName('html')[0].lang = language;
  }
  return i18n.changeLanguage(language);
};
export var getLocale = function getLocale() {
  var lang = getCurrentLang();
  var locales = {
    pt: 'pt-BR',
    en: 'en-US',
    es: 'es-ES'
    // fr: 'fr-FR'
  };

  return locales[lang] || locales['pt'];
};
export var currency = function currency() {
  var num = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  var currency = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'BRL';
  var locale = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : getLocale();
  return num.toLocaleString(locale, {
    style: 'currency',
    currency: currency
  });
};
export var translate = function translate(key) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return i18n.t(key, options);
};
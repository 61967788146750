export var initialState = {
  byId: {},
  byStatus: {},
  sizes: {},
  fetching: false,
  fetched: false,
  error: null
};
export var PER_PAGE = 10;
export var ORDER_BY = {
  MostRecent: 'LIVE_START_AT_DESCENDING',
  Oldest: 'LIVE_START_AT'
};
export var STATUS = {
  Creating: 'CREATING',
  Created: 'CREATED',
  Started: 'STARTED',
  Closing: 'CLOSING',
  Completed: 'COMPLETED',
  Deleted: 'DELETED',
  Failed: 'FAILED'
};
export var types = {
  FETCH_FULFILLED: 'lives/FETCH_FULFILLED',
  FETCH_REJECTED: 'lives/FETCH_REJECTED',
  FETCH_PENDING: 'lives/FETCH_PENDING',
  CLEAR_LIVES: 'lives/CLEAR_LIVES',
  CANCEL_LIVE: 'lives/CANCEL_LIVE'
};
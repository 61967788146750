import { currentZone } from "../../../utils/timezones";
export var PROVIDERS = {
  HOTMART: 'HOTMART',
  YOUTUBE: 'YOUTUBE',
  VIMEO: 'VIMEO'
};
export var initialState = {
  data: {
    collectionId: undefined,
    zoneId: currentZone,
    liveStartAt: undefined,
    provider: '',
    name: '',
    description: '',
    url: '',
    file: null,
    src: ''
  },
  validation: {
    url: null,
    name: null,
    description: null,
    track: false,
    file: null,
    date: {
      isValid: true,
      errorMessage: ''
    },
    time: {
      isTyped: true,
      isValid: true,
      errorMessage: ''
    }
  },
  publish: {
    pending: false,
    success: false,
    error: false,
    data: undefined
  }
};
export var SCHEDULE_PARAMS = {
  projectId: undefined,
  fileName: undefined,
  objectKey: undefined,
  liveStartAt: undefined,
  createVideoOnDemand: true,
  enableVodPlayback: true,
  description: '',
  name: '',
  url: '',
  zoneId: '',
  provider: PROVIDERS.YOUTUBE,
  visibility: 'PUBLIC',
  createdFrom: 'SPARKLE'
};
export var types = {
  SET_STATE: 'newLive/SET_STATE'
};
export var LIVE_FEATURE_NAME = 'HOTMART_LIVE_PROVIDER_FEATURE';
export var REMOTE_CONFIG_LIVE_FEATURE_NAME = 'enable_hotmart_live';
export var REMOTE_CONFIG_HOTMART_PROVIDER_FEATURE_NAME = 'enable_live_hotmart_provider';
import _defineProperty from "/drone/src/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import IO from "../utils/IO";
import { endpoints } from "../utils/config";
import { getLocale } from "../utils/i18n";
var TOSTYPES = [0, 2, 3];

// ACTIONS
var getConfig = function getConfig() {
  var options = IO.auth();
  return _objectSpread(_objectSpread({}, options), {}, {
    headers: _objectSpread(_objectSpread({}, options.headers), {}, {
      'Content-Type': 'application/json'
    })
  });
};
export var isLatestVersion = function isLatestVersion() {
  var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  return getTosStatus(type).then(function (status) {
    return status && status.lastAcceptedDate >= status.lastPublishedDate;
  }).catch(function (err) {
    var isKnownError = err && [401, 404, 500].indexOf(err.status) > -1;
    return isKnownError;
  });
};
export var getLatestTos = function getLatestTos() {
  var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  var locale = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : getLocale();
  return IO.get("".concat(endpoints.APIv1, "/tos/").concat(type, "/latest")).then(function (res) {
    return res.data;
  }).catch(function (err) {
    console.error(err.response);
  });
};
export var getTosStatus = function getTosStatus() {
  var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  return IO.get("".concat(endpoints.APIv1, "/tos/").concat(type, "/status"), null, getConfig()).then(function (res) {
    return res.data;
  }).catch(function (err) {
    console.error(err.response);
    var error = _objectSpread({}, err.response);
    throw error;
  });
};
export var acceptTos = function acceptTos() {
  var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  return IO.put("".concat(endpoints.APIv1, "/tos/").concat(type, "/accept"), null, getConfig()).then(function (res) {
    return res.data;
  }).catch(function (err) {
    console.error(err.response);
  });
};
export var acceptAll = function acceptAll() {
  var tosPromises = TOSTYPES.map(function (type) {
    return acceptTos(type);
  });
  return Promise.all(tosPromises).catch(console.error);
};
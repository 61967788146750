import _defineProperty from "/drone/src/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { createReducer } from "../utils/redux";
export var initialState = {
  open: false,
  data: {}
};
export var types = {
  SET_THREAD: 'thread/SET_THREAD'
};

// REDUCERS
var setThread = function setThread(state, action) {
  var _action$payload = action.payload,
    _action$payload$data = _action$payload.data,
    data = _action$payload$data === void 0 ? state.data : _action$payload$data,
    _action$payload$open = _action$payload.open,
    open = _action$payload$open === void 0 ? state.open : _action$payload$open;
  return _objectSpread(_objectSpread({}, state), {}, {
    data: data,
    open: open
  });
};
export var reducer = createReducer(initialState, _defineProperty({}, types.SET_THREAD, setThread));

// ACTIONS
export var setData = function setData() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return function (dispatch, getState) {
    var payload = {
      data: data
    };
    var type = types.SET_THREAD;
    dispatch({
      type: type,
      payload: payload
    });
  };
};
export var open = function open() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return function (dispatch) {
    var payload = {
      data: data,
      open: true
    };
    var type = types.SET_THREAD;
    dispatch({
      type: type,
      payload: payload
    });
  };
};
export var close = function close() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return function (dispatch) {
    var payload = {
      data: data,
      open: false
    };
    var type = types.SET_THREAD;
    dispatch({
      type: type,
      payload: payload
    });
  };
};
var _defineProperty = require("/drone/src/node_modules/next/node_modules/@babel/runtime/helpers/defineProperty");
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
var DEV_ENV = 'staging';
var defaultConfig = require('./' + DEV_ENV);
var _defaultConfig$cookie = defaultConfig.cookie,
  domain = _defaultConfig$cookie.domain,
  subdomain = _defaultConfig$cookie.subdomain;
var endpoints = _objectSpread(_objectSpread({}, defaultConfig.endpoints), {}, {
  APP: "https://dev.".concat(subdomain, ".").concat(domain, ":3000")
});
module.exports = _objectSpread(_objectSpread({}, defaultConfig), {}, {
  DEV_ENV: DEV_ENV,
  endpoints: endpoints
});
import { keyframes, css } from 'styled-components';
export var mapStyle = function mapStyle(prop, styleMap) {
  var style = styleMap[prop];
  return style ? css(["", ""], styleMap[prop]) : '';
};
export var fontStyle = function fontStyle(size, weight, color) {
  return css(["font-family:'Nunito Sans',sans-serif;font-size:", "px;font-weight:", ";color:", ";font-style:normal;font-stretch:normal;line-height:normal;letter-spacing:normal;"], size, weight, color);
};
export var truncate = css(["text-overflow:ellipsis;white-space:nowrap;overflow:hidden;"]);
export var progress = keyframes(["0%{width:0%;}100%{width:100%;}"]);
export var fadeOut = keyframes(["0%{opacity:1;}100%{opacity:0;}"]);
export var blurOut = keyframes(["0%{filter:blur(20px);}100%{filter:blur(0);}"]);
export var animations = {
  progress: css(["animation:", " 3s cubic-bezier(0,0.5,0.2,1);"], progress),
  fadeOut: css(["animation:", " .5s linear;"], fadeOut),
  blurOut: css(["animation:", " .5s linear;"], blurOut)
};
export var safeArea = function safeArea(side, cssProp) {
  var value = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '0px';
  return css(["@supports (", ":constant(safe-area-inset-bottom)){--safe-area-inset-", ":constant(safe-area-inset-", ",0px);", ":calc(", " + var(--safe-area-inset-", "));}@supports (", ":env(safe-area-inset-bottom)){--safe-area-inset-", ":env(safe-area-inset-", ",0px);", ":calc(", " + var(--safe-area-inset-", "));}"], cssProp, side, side, cssProp, value, side, cssProp, side, side, cssProp, value, side);
};
export var sticky = css(["position:sticky;position:-webkit-sticky;position:-moz-sticky;position:-ms-sticky;position:-o-sticky;"]);
var nextRoutes = require('next-routes-extended');
var _require = require("../../shared/constants/RoutesPaths"),
  RoutesPaths = _require.RoutesPaths;
var routes = nextRoutes();
var createFnTrigger = function createFnTrigger(prop) {
  return function (fn) {
    var oldFn = routes.Router[prop];
    routes.Router[prop] = function () {
      if (oldFn && typeof oldFn === 'function') oldFn();
      if (fn && typeof fn === 'function') fn(routes.Router);
    };
  };
};
routes.Router.custom = {
  onRouteChangeStart: createFnTrigger('onRouteChangeStart'),
  onRouteChangeComplete: createFnTrigger('onRouteChangeComplete'),
  onRouteChangeError: createFnTrigger('onRouteChangeError')
};

// Named routes
var routeList = [{
  name: 'communities/index',
  path: "".concat(RoutesPaths.HOME)
}, {
  name: 'communities/login',
  path: "/:lang?".concat(RoutesPaths.LOGIN, "/:redirect?")
}, {
  name: 'communities/logout',
  path: "".concat(RoutesPaths.LOGOUT, "/:redirect?")
}, {
  name: 'communities/publish',
  path: RoutesPaths.PUBLISH
}, {
  name: 'communities/editSpark',
  path: "".concat(RoutesPaths.PUBLISH, "/:sparkId")
}, {
  name: 'communities/profile',
  path: "/:lang?".concat(RoutesPaths.PROFILE, "/:userName/:tab?")
}, {
  name: 'communities/me',
  path: "/:lang?".concat(RoutesPaths.ME, "/:tab?")
}, {
  name: 'communities/donation',
  path: "/:lang?".concat(RoutesPaths.PROFILE, "/:userName/subscriptions/:offerId")
}, {
  name: 'communities/spark',
  path: "/:lang?".concat(RoutesPaths.SPARK, "/:sparkId/:userName?/:trackUrl?/:slug?")
}, {
  name: 'communities/track',
  path: "/:lang?".concat(RoutesPaths.TRACK, "/:userName?/:trackUrl/:tab?")
}, {
  name: 'communities/bonus',
  path: "/:lang?".concat(RoutesPaths.BONUS, "/:trackUrl?")
}, {
  name: 'communities/sparkEmbed',
  path: "/:lang?".concat(RoutesPaths.SPARK_EMBED, "/:sparkId")
}, {
  name: 'communities/trackEmbed',
  path: "/:lang?".concat(RoutesPaths.TRACK_EMBED, "/:trackUrl")
}, {
  name: 'communities/incorporate',
  path: "/:lang?".concat(RoutesPaths.INCORPORATE, "/:id/:type")
}, {
  name: 'communities/discover',
  path: "".concat(RoutesPaths.DISCOVER, "/:tab?")
}, {
  name: 'communities/auth/login',
  path: "/:lang?".concat(RoutesPaths.AUTH_LOGIN)
}, {
  name: 'communities/auth/logout',
  path: "/communities/auth/logout"
}, {
  name: 'communities/auth/renew',
  path: "/:lang?".concat(RoutesPaths.AUTH_RENEW)
}, {
  name: 'communities/newLive',
  path: "".concat(RoutesPaths.LIVE, "/new")
}, {
  name: 'communities/live',
  path: "".concat(RoutesPaths.LIVE, "/:tab?")
}, {
  name: 'communities/code',
  path: "".concat(RoutesPaths.CODE, "/:code")
}, {
  name: 'communities/cardTos',
  path: "".concat(RoutesPaths.CARD_TOS, "/:lang?/:type?")
}, {
  name: 'communities/cardPrivacy',
  path: "".concat(RoutesPaths.CARD_PRIVACY, "/:lang?/:type?")
}, {
  name: 'communities/cardPoints',
  path: "".concat(RoutesPaths.CARD_POINTS, "/:lang?/:type?")
}];
routeList.forEach(function (route) {
  routes.add(route.name, route.path);
});
module.exports = routes;
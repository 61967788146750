import promiseMiddleware from 'redux-promise-middleware';
import thunkMiddleware from 'redux-thunk';
import { connect } from 'react-redux';
import { createLogger } from 'redux-logger';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { initialStateModel, reducers } from "./ducks";
import { ENV } from "./utils/config";
import Auth from "./utils/Auth";
export var resetAppState = function resetAppState() {
  return function (dispatch) {
    Auth.logout();
    dispatch({
      type: 'RESET_APP_STATE'
    });
  };
};
export var initStore = function initStore() {
  var initialState = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialStateModel;
  var appReducer = combineReducers(reducers);
  var rootReducer = function rootReducer(state, action) {
    if (action.type === 'RESET_APP_STATE') {
      state = undefined;
    }
    return appReducer(state, action);
  };
  var middlewares = [thunkMiddleware, promiseMiddleware()];
  var dev = process.browser && ENV !== 'production';
  if (dev) {
    var _logger = createLogger({
      collapsed: true
    });
    middlewares.push(_logger);
  }
  var store = createStore(rootReducer, initialState, applyMiddleware.apply(void 0, middlewares));
  return store;
};
export var nextConnect = function nextConnect() {
  var stateProps = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  var dispatchProps = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var mergeProps = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  return function (Component) {
    return connect(stateProps, dispatchProps, mergeProps, options)(Component);
  };
};
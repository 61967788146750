import _defineProperty from "/drone/src/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { getCleanText } from "./strings";
import { getSparkAspectRatio } from "./sparks";
import * as UsersMedia from "./media/users";
import { getParam } from "./remote-config";
var defaultOptions = {
  keyBy: 'id',
  schemas: [],
  parser: function parser(el) {
    return el;
  }
};
export var arr = function arr(_arr) {
  var newOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var options = _objectSpread(_objectSpread({}, defaultOptions), newOptions);
  var byId = _arr.reduce(function (obj, cur, i) {
    var id = cur[options.keyBy];
    obj[id] = options.parser(cur);
    return obj;
  }, {});
  var allIds = Object.keys(byId);
  return {
    byId: byId,
    allIds: allIds
  };
};
export var normalizeSpark = function normalizeSpark(spark) {
  var canDisableComments = Boolean(getParam('disable_comments_enabled'));
  var titleDetail = spark.description || spark.title || spark.content;
  var titleCard = spark.title || spark.description || spark.content;
  var customTitle = {
    title_card: titleCard && getCleanText(titleCard).trim().split('\n', 1)[0],
    title_detail: titleDetail && getCleanText(titleDetail),
    title_raw: titleDetail
  };
  return _objectSpread(_objectSpread({}, spark), {}, {
    aspectRatio: getSparkAspectRatio(spark),
    ownerUserName: spark.ownerUserName || spark.ownerUsername,
    custom: _objectSpread(_objectSpread({}, customTitle), {}, {
      secure: spark.ownerCollectionVisible === false || spark.ownerCollectionPublic === false
    }),
    isCommentsEnabled: canDisableComments ? spark.isCommentsEnabled : true
  });
};
export var normalizeTrack = function normalizeTrack(track) {
  return _objectSpread(_objectSpread({}, track), {}, {
    ownerUserName: track.ownerUserName || track.ownerUsername,
    custom: {
      secure: track.isPublic === false || track.isVisible === false
    }
  });
};
export var normalizeComment = function normalizeComment(comment, user) {
  return _objectSpread(_objectSpread({}, comment), {}, {
    authorId: user.id,
    author: user.name,
    authorUserName: user.userName,
    authorImagePath: user.image,
    authorVerified: user.verified
  });
};
export var getProcessingTitle = function getProcessingTitle(spark) {
  var titleCard = spark.title || spark.description || spark.content;
  return titleCard ? getCleanText(titleCard).trim().split('\n', 1)[0] : '';
};
export var getAuthor = function getAuthor(provider) {
  return provider && {
    id: provider.authorId,
    name: provider.author || provider.authorName || '',
    userName: provider.authorUserName,
    hasImage: Boolean(provider.authorHasImage),
    image: Boolean(provider.authorImagePath) && UsersMedia.getImage(provider.authorId, 40),
    verified: provider.authorVerified,
    memberType: provider.authorMemberType
  };
};
export var getOwner = function getOwner(provider) {
  return provider && {
    id: provider.ownerId,
    name: provider.owner || provider.ownerName || '',
    userName: provider.ownerUsername,
    hasImage: Boolean(provider.ownerImageURL),
    image: Boolean(provider.ownerImageURL) && UsersMedia.getImage(provider.ownerId, 40),
    verified: provider.ownerVerified
  };
};
import _defineProperty from "/drone/src/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
export var BLOCK_TYPES = [{
  label: 'Title',
  style: 'header-one',
  element: 'h1',
  token: {
    start: '=',
    end: '='
  },
  regex: /(?:^=)(.+)(?:=$)/m
}, {
  label: 'Bold',
  style: 'bold',
  element: 'b',
  token: {
    start: '*',
    end: '*'
  },
  regex: /(?:^\*)(.+)(?:\*$)/m
}, {
  label: 'Blockquote',
  style: 'blockquote',
  element: 'blockquote',
  token: {
    start: '_',
    end: '_'
  },
  regex: /(?:^_)(.+)(?:_$)/m
}, {
  label: 'Code',
  style: 'code-block',
  element: 'code',
  token: {
    start: '::\n',
    end: '\n::'
  },
  regex: /(?:^::[\t ]*\n)([^]+?)(?:\n::[\t ]*$)/m,
  multiline: true
}];
export var getCleanText = function getCleanText(text) {
  return BLOCK_TYPES.reduce(function (acc, type) {
    return acc.split(type.regex).join('');
  }, text);
};
export var getTextType = function getTextType(text) {
  return BLOCK_TYPES.reduce(function (acc, type) {
    var match = type.regex.exec(text.trim());
    if (match) {
      return _objectSpread(_objectSpread({}, type), {}, {
        match: match
      });
    }
    return acc;
  }, null);
};
export var truncate = function truncate(str) {
  var len = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 100;
  var after = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '...';
  var strLen = len - after.length;
  if (str && str.length > strLen) {
    return str.substring(0, strLen) + after;
  }
  return str;
};
export var capitalizeWord = function capitalizeWord(word) {
  if (typeof word !== 'string') return '';
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
};
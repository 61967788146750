import IO from "../IO";
import HlsUtils from "../hls";
import { endpoints } from "../config";
import { authMediaUrl } from "../url";
export var SPARK_SNAPSHOT_SIZES = {
  xl: 480,
  lg: 320,
  md: 240,
  sm: 120,
  xs: 50
};
export var getSnapshotUrl = function getSnapshotUrl(sparkId) {
  var secure = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var width = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  var height = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
  if (!sparkId) return null;
  var authPath = secure ? 'secureSnapshot' : 'snapshot';
  var path = "".concat(endpoints.APIv1, "/news/").concat(sparkId, "/").concat(authPath);
  var dimensions = width > 0 || height > 0 ? "".concat(width, "x").concat(height) : null;
  var params = {
    dimensions: dimensions,
    redirect: false
  };
  var options = {
    authenticated: Boolean(secure)
  };
  return IO.get(path, params, options).then(function (res) {
    return res.data.data;
  });
};
export var getSnapshot = function getSnapshot(sparkId) {
  var secure = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var width = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  var height = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
  if (!sparkId) return null;
  var dimensions = width > 0 || height > 0 ? "dimensions=".concat(width, "x").concat(height) : '';
  var authPath = secure ? 'secureSnapshot' : 'snapshot';
  var path = "".concat(endpoints.APIv1, "/news/").concat(sparkId, "/").concat(authPath, "?").concat(dimensions);
  return secure ? authMediaUrl(path) : path;
};
export var getSnapshotBySize = function getSnapshotBySize(sparkId) {
  var secure = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var size = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'md';
  var snapshotSize = SPARK_SNAPSHOT_SIZES[size];
  return getSnapshot(sparkId, secure, snapshotSize, snapshotSize);
};
export var getMediaUrl = function getMediaUrl(sparkId) {
  var secure = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  if (!sparkId) return null;
  var authPath = secure ? 'secureDownloadLink' : 'downloadLink';
  var path = "".concat(endpoints.APIv1, "/news/").concat(sparkId, "/").concat(authPath);
  var headers = {
    'Cache-Control': 'no-cache'
  };
  return IO.get(path, null, {
    authenticated: Boolean(secure),
    headers: headers
  }).then(function (res) {
    return res.data.data;
  }).catch(function () {
    return null;
  });
};
export var getMediaHls = function getMediaHls(sparkId, authenticated) {
  var authPath = authenticated ? '' : 'public';
  var path = "".concat(endpoints.APIv2, "/news/").concat(sparkId, "/download/").concat(authPath);
  var params = {
    redirect: false
  };
  var options = {
    authenticated: authenticated
  };
  return IO.get(path, params, options).then(function (res) {
    var _res$data = res.data,
      data = _res$data.data,
      cookies = _res$data.cookies;
    HlsUtils.parseCookies(cookies);
    return data;
  });
};
export var getMedia = function getMedia(sparkId) {
  var secure = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var hls = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  if (!sparkId) return null;
  if (hls) return getMediaHls(sparkId, secure);
  var authPath = secure ? 'secureDownloadLink' : 'download';
  var path = "".concat(endpoints.APIv1, "/news/").concat(sparkId, "/").concat(authPath);
  if (secure) {
    return IO.get(path, null, {
      authenticated: Boolean(secure)
    }).then(function (res) {
      return res.data.data;
    }).catch(function () {
      return null;
    });
  }
  return path;
};
export var getPlayerData = function getPlayerData(sparkId) {
  var path = "".concat(endpoints.APIv2, "/news/").concat(sparkId, "/download/player");
  var options = {
    authenticated: true
  };
  return IO.get(path, null, options).then(function (res) {
    return res.data;
  });
};
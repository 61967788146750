import _toConsumableArray from "/drone/src/node_modules/next/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import { v4 as uuidv4 } from 'uuid';
export function debounce(func, wait, immediate) {
  var _arguments = arguments,
    _this = this;
  var timeout;
  return function () {
    var context = _this;
    var args = _arguments;
    var later = function later() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}
export function throttle(func, minimumWait) {
  var _arguments2 = arguments,
    _this2 = this;
  var lastCallTime = 0;
  return function () {
    var context = _this2;
    var args = _arguments2;
    var now = new Date().getTime();
    if (Math.abs(now - lastCallTime) < minimumWait) {
      return;
    }
    lastCallTime = now;
    func.apply(context, args);
  };
}
export var uuid = function uuid() {
  return uuidv4();
};
export var guid = function guid() {
  var s4 = function s4() {
    return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
  };
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
};
export var paginate = function paginate() {
  var arr = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var perPage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  if (!arr.length) return [];
  return arr.reduce(function (acc, el) {
    var page = acc[acc.length - 1];
    var size = page.length;
    if (size < perPage) {
      page.push(el);
    } else {
      acc[acc.length] = [el];
    }
    return acc;
  }, [[]]);
};
export var keyBy = function keyBy(arr, key) {
  return arr.map(function (el) {
    return el[key];
  });
};
export var unique = function unique(arr, key) {
  var keysArr = key ? keyBy(arr, key) : arr;
  var filtered = keysArr.filter(function (key, i) {
    return keysArr.indexOf(key) === i;
  });
  return key ? filtered.map(function (elKey) {
    return arr.find(function (el) {
      return el[key] === elKey;
    });
  }) : filtered;
};
export var containsProps = function containsProps(obj, props) {
  return props.every(function (prop) {
    return prop in obj || obj[prop] !== undefined;
  });
};
export var sortBy = function sortBy(arr) {
  var key = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'date';
  return arr.sort(function (a, b) {
    return a[key] - b[key];
  });
};
export var pushBy = function pushBy() {
  var array = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var value = arguments.length > 1 ? arguments[1] : undefined;
  if (!array.includes(value)) {
    return [].concat(_toConsumableArray(array), [value]);
  }
  return _toConsumableArray(array);
};
export var unshiftBy = function unshiftBy() {
  var array = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var value = arguments.length > 1 ? arguments[1] : undefined;
  if (!array.includes(value)) {
    return [value].concat(_toConsumableArray(array));
  }
  return _toConsumableArray(array);
};
export var findAncestor = function findAncestor(el, sel) {
  while ((el = el.parentElement) && !(el.matches || el.matchesSelector).call(el, sel)) {
    console.error(el, el.parentNode);
  }
  return el;
};
export var hasFocus = function hasFocus(el) {
  return el && document && el.contains(document.activeElement);
};
export var chunkArray = function chunkArray(array) {
  var chunkSize = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 4;
  var chunkedArray = [];
  for (var _i = 0; _i < array.length; _i += chunkSize) {
    chunkedArray.push(array.slice(_i, _i + chunkSize));
  }
  return chunkedArray;
};
export var clearUndefinedProps = function clearUndefinedProps() {
  var object = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return Object.keys(object).reduce(function (filteredOjbect, key) {
    var _filteredOjbect = filteredOjbect;
    if (object[key] !== undefined) _filteredOjbect[key] = object[key];
    return _filteredOjbect;
  }, {});
};
import NextI18Next from 'next-i18next';
var defaultLanguage = 'pt-br';
var languages = ['en', 'es'];
// const languages = ['en', 'es', 'fr']
var isDev = process.env.APP_ENV === 'development';
var localePathBrowser = isDev ? '/locales' : '/_sparkle/_next/public/locales';
var localePath = typeof window === 'undefined' ? './app/public/locales' : localePathBrowser;
var NextI18NextInstance = new NextI18Next({
  load: 'currentOnly',
  localePath: localePath,
  defaultLanguage: defaultLanguage,
  fallbackLng: defaultLanguage,
  otherLanguages: languages,
  localeSubpaths: {},
  lowerCaseLng: true,
  localeDetection: true
});
export default NextI18NextInstance;
NextI18NextInstance.i18n.languages = [defaultLanguage].concat(languages);
// Created to force zero as plural in Brazilian Portuguese
var PT_PLURAL_RULE = {
  'numbers': [1, 2],
  'plurals': function plurals(n) {
    /* eslint-disable */
    return Number(n != 1);
    /* eslint-enable */
  }
};

NextI18NextInstance.i18n.services.pluralResolver.addRule('pt-BR', PT_PLURAL_RULE);
NextI18NextInstance.i18n.services.pluralResolver.addRule('pt', PT_PLURAL_RULE);
var appWithTranslation = NextI18NextInstance.appWithTranslation,
  withTranslation = NextI18NextInstance.withTranslation,
  i18n = NextI18NextInstance.i18n,
  Trans = NextI18NextInstance.Trans;
export { appWithTranslation, withTranslation, i18n, Trans };
import IO from "../utils/IO";
import { endpoints } from "../utils/config";
export var trackingEvent = function trackingEvent(body) {
  var auth = IO.auth();
  var authPath = auth ? 'event' : 'publicEvent';
  var path = "/tracking/".concat(authPath);
  return IO.post(endpoints.APIv1 + path, body, auth).then(function (res) {
    return res.data;
  }).catch(function (err) {
    if (err && err.response) {
      var _err$response = err.response,
        status = _err$response.status,
        statusText = _err$response.statusText;
      return {
        status: status,
        statusText: statusText
      };
    }
  });
};
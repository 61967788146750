import { colors, cardShadow } from "./variables";
var ANIMATION_TIME = '0.2s';
var fadeInOut = function fadeInOut(props) {
  return "\n  ".concat(props.visible !== false ? "\n    visibility: visible;\n    opacity: 1;\n    " : "\n    visibility: hidden;\n    opacity: 0;\n    ", "\n\n  ").concat(props.animated ? "\n  will-change: opacity, visibility;\n  transition: visibility ".concat(props.visible ? '0s' : ANIMATION_TIME, " ease-out, opacity ").concat(ANIMATION_TIME, " ease-out;\n  ") : '', "\n");
};
var scaleInOut = function scaleInOut(props) {
  return "\n  transform: ".concat(props.visible !== false ? 'scaleY(1)' : props.animated ? 'scaleY(0.25)' : 'scaleY(0)', ";\n\n  ").concat(props.animated ? "\n    will-change: transform;\n    transition: transform ".concat(ANIMATION_TIME, " ease-out;\n    transform-origin: 50% 100%;\n  ") : '', "\n");
};
var wrapper = "\n  position: fixed;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  z-index: +110;\n";
var overlay = "\n  position: fixed;\n  width: 100%;\n  height: 100%;\n  top: 0;\n  left: 0;\n  background: ".concat(colors.gray80, ";\n  opacity: 0.5;\n  z-index: +10;\n");
var contentWrapper = "\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-end;\n";
var content = "\n  position: relative;\n  background: ".concat(colors.white, ";\n  max-height: 80vh;\n  padding: 24px;\n  color: ").concat(colors.gray80, ";\n  border-radius: 0;\n  z-index: +20;\n  -webkit-overflow-scrolling: touch;\n  overflow: auto;\n  ").concat(cardShadow, "\n");
export default {
  wrapper: wrapper,
  overlay: overlay,
  contentWrapper: contentWrapper,
  content: content,
  fadeInOut: fadeInOut,
  scaleInOut: scaleInOut
};
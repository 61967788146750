var formatDistanceLocale = {
  lessThanXSeconds: 'ahora',
  xSeconds: 'ahora',
  halfAMinute: 'ahora',
  lessThanXMinutes: 'ahora',
  xMinutes: {
    one: '1 minuto',
    other: '{{count}} minutos',
    strict: '{{count}} min'
  },
  aboutXHours: {
    one: '1 hora',
    other: '{{count}} horas',
    strict: '{{count}} h'
  },
  xHours: {
    one: '1 hora',
    other: '{{count}} horas',
    strict: '{{count}} h'
  },
  xDays: {
    one: '1 día',
    other: '{{count}} días',
    strict: '{{count}} d'
  },
  aboutXWeeks: {
    one: '1 semana',
    other: '{{count}} semanas',
    strict: '{{count}} sem'
  },
  xWeeks: {
    one: '1 semana',
    other: '{{count}} semanas',
    strict: '{{count}} sem'
  },
  aboutXMonths: {
    one: '1 mes',
    other: '{{count}} meses',
    strict: '{{count}} m'
  },
  xMonths: {
    one: '1 mes',
    other: '{{count}} meses',
    strict: '{{count}} m'
  },
  aboutXYears: {
    one: '1 año',
    other: '{{count}} años',
    strict: '{{count}} a'
  },
  xYears: {
    one: '1 año',
    other: '{{count}} años',
    strict: '{{count}} a'
  },
  overXYears: {
    one: '1 año',
    other: '{{count}} años',
    strict: '{{count}} a'
  },
  almostXYears: {
    one: '1 año',
    other: '{{count}} años',
    strict: '{{count}} a'
  }
};
export default function formatDistance(token, count, options) {
  options = options || {};
  var result;
  if (typeof formatDistanceLocale[token] === 'string') {
    result = formatDistanceLocale[token];
  } else if (!options.addSuffix) {
    result = formatDistanceLocale[token].strict.replace('{{count}}', count);
  } else if (count === 1) {
    result = formatDistanceLocale[token].one;
  } else {
    result = formatDistanceLocale[token].other.replace('{{count}}', count);
  }
  if (options.addSuffix) {
    if (options.comparison > 0) {
      return 'en ' + result;
    } else {
      return 'hace ' + result;
    }
  }
  return result;
}
import formatRelative from 'date-fns/locale/es/_lib/formatRelative/index';
import match from 'date-fns/locale/es/_lib/match/index';
import localize from "./_lib/localize/index";
import formatDistance from "./_lib/formatDistance/index";
import formatLong from "./_lib/formatLong/index";
var locale = {
  code: 'es',
  formatDistance: formatDistance,
  formatLong: formatLong,
  formatRelative: formatRelative,
  localize: localize,
  match: match,
  options: {
    weekStartsOn: 1 /* Monday */,
    firstWeekContainsDate: 1
  }
};
export default locale;
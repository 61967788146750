import _defineProperty from "/drone/src/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
var _createReducer;
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { createReducer } from "../utils/redux";

// ACTIONS
export var initialState = {
  byId: {}
};
export var types = {
  START: 'uploads/START',
  PROGRESS: 'uploads/PROGRESS',
  SUCCESS: 'uploads/SUCCESS',
  FAILURE: 'uploads/FAILURE'
};

// REDUCERS
var uploadStartReducer = function uploadStartReducer(state, action) {
  var _action$payload = action.payload,
    fileId = _action$payload.fileId,
    callbacks = _action$payload.callbacks,
    file = _action$payload.file,
    context = _action$payload.context;
  return _objectSpread(_objectSpread({}, state), {}, {
    byId: _objectSpread(_objectSpread({}, state.byId), {}, _defineProperty({}, fileId, {
      callbacks: callbacks,
      context: context,
      name: file.name,
      type: file.type,
      size: file.size,
      startDate: new Date(),
      loaded: 0,
      progress: 0,
      endDate: null,
      total: null,
      success: null,
      error: null
    }))
  });
};
var uploadProgressReducer = function uploadProgressReducer(state, action) {
  var _action$payload2 = action.payload,
    fileId = _action$payload2.fileId,
    data = _action$payload2.data;
  return _objectSpread(_objectSpread({}, state), {}, {
    byId: _objectSpread(_objectSpread({}, state.byId), {}, _defineProperty({}, fileId, _objectSpread(_objectSpread({}, state.byId[fileId]), {}, {
      loaded: data.loaded,
      total: data.total,
      progress: Math.floor(data.loaded * 100 / data.total)
    })))
  });
};
var uploadSuccessReducer = function uploadSuccessReducer(state, action) {
  var _action$payload3 = action.payload,
    fileId = _action$payload3.fileId,
    data = _action$payload3.data;
  return _objectSpread(_objectSpread({}, state), {}, {
    byId: _objectSpread(_objectSpread({}, state.byId), {}, _defineProperty({}, fileId, _objectSpread(_objectSpread({}, state.byId[fileId]), {}, {
      endDate: new Date(),
      success: data
    })))
  });
};
var uploadFailureReducer = function uploadFailureReducer(state, action) {
  var _action$payload4 = action.payload,
    fileId = _action$payload4.fileId,
    data = _action$payload4.data;
  return _objectSpread(_objectSpread({}, state), {}, {
    byId: _objectSpread(_objectSpread({}, state.byId), {}, _defineProperty({}, fileId, _objectSpread(_objectSpread({}, state.byId[fileId]), {}, {
      endDate: new Date(),
      error: data
    })))
  });
};
export var reducer = createReducer(initialState, (_createReducer = {}, _defineProperty(_createReducer, types.START, uploadStartReducer), _defineProperty(_createReducer, types.PROGRESS, uploadProgressReducer), _defineProperty(_createReducer, types.SUCCESS, uploadSuccessReducer), _defineProperty(_createReducer, types.FAILURE, uploadFailureReducer), _createReducer));
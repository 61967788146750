export var keyList = {
  NEW_COLLECTIONS: 'new_collections',
  CLOSED_LIVE_POPUP: 'closed_live_popup',
  EMAIL_LOGIN: 'email_login',
  OPEN_CHECKOUT_AUTOMATICALLY: 'open_checkout_automatically',
  PUBLISH_VALUES: 'publish_values',
  FIREBASE_LAST_UPDATE: 'firebase_last_update'
};
export var get = function get(key) {
  if (typeof window !== 'undefined') {
    return window.localStorage.getItem(key);
  }
  return null;
};
export var set = function set(key, value) {
  if (typeof window !== 'undefined') {
    window.localStorage.setItem(key, value);
  }
  return get(key);
};
export var remove = function remove(key) {
  if (typeof window !== 'undefined') {
    window.localStorage.removeItem(key);
  }
};
export var clear = function clear() {
  if (typeof window !== 'undefined') {
    window.localStorage.clear();
  }
};
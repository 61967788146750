import formatRelative from 'date-fns/locale/en-US/_lib/formatRelative/index';
import localize from 'date-fns/locale/en-US/_lib/localize/index';
import match from 'date-fns/locale/en-US/_lib/match/index';
import formatDistance from "./_lib/formatDistance/index";
import formatLong from "./_lib/formatLong/index";
var locale = {
  code: 'en-US',
  formatDistance: formatDistance,
  formatLong: formatLong,
  formatRelative: formatRelative,
  localize: localize,
  match: match,
  options: {
    weekStartsOn: 0 /* Sunday */,
    firstWeekContainsDate: 1
  }
};
export default locale;
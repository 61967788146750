import _toConsumableArray from "/drone/src/node_modules/next/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "/drone/src/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
var _createReducer;
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import IO from "../utils/IO";
import { createReducer } from "../utils/redux";
import { endpoints } from "../utils/config";
import { getLocale } from "../utils/i18n";
import { getParam } from "../utils/remote-config";
export var types = {
  FETCH: 'users/FETCH',
  FETCH_FULFILLED: 'users/FETCH_FULFILLED',
  FETCH_REJECTED: 'users/FETCH_REJECTED',
  FETCH_PENDING: 'users/FETCH_PENDING',
  FETCH_FOLLOWERS: 'users/FETCH_FOLLOWERS',
  FETCH_FOLLOWERS_FULFILLED: 'users/FETCH_FOLLOWERS_FULFILLED',
  FETCH_FOLLOWERS_REJECTED: 'users/FETCH_FOLLOWERS_REJECTED',
  FETCH_FOLLOWERS_PENDING: 'users/FETCH_FOLLOWERS_PENDING',
  CLEAR_FOLLOWERS: 'users/CLEAR_FOLLOWERS',
  REMOVE_FOLLOWER: 'user/REMOVE_FOLLOWER',
  SET_USER_PROP: 'user/SET_USER_PROP'
};
var initialByFollowersState = {
  users: [],
  nextPageToken: null
};
export var initialState = {
  byUserName: {},
  byFollowers: {},
  followersSize: 0,
  fetching: false,
  fetched: false,
  error: null
};
var pushByUserName = function pushByUserName(state, payload) {
  var data = payload.data;
  var users = Array.isArray(data) ? data : [data];
  var byUserName = users.reduce(function (acc, user) {
    if (user) {
      var _userName = user.userName.toLowerCase();
      var _oldUser = state.byUserName[_userName];
      return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, _userName, _objectSpread(_objectSpread({}, _oldUser), user)));
    }
    return acc;
  }, {});
  return _objectSpread(_objectSpread({}, state.byUserName), byUserName);
};
var pushByFollowers = function pushByFollowers(state, payload) {
  var data = payload.data,
    trackId = payload.trackId,
    nextPageToken = payload.nextPageToken,
    rows = payload.rows,
    size = payload.size;
  var initialUsersState = _objectSpread(_objectSpread({}, initialByFollowersState), state.byFollowers[trackId]);
  var initialUsers = initialUsersState.users || [];
  var users = data.reduce(function (acc, user) {
    var userName = user && user.userName.toLowerCase();
    if (userName && !initialUsers.includes(userName)) {
      return [].concat(_toConsumableArray(acc), [userName]);
    }
    return _toConsumableArray(acc);
  }, initialUsers);
  var lastPage = data.length !== rows && users.length >= size;
  return _objectSpread(_objectSpread({}, state.byFollowers), {}, _defineProperty({}, trackId, _objectSpread(_objectSpread({}, initialUsersState), {}, {
    users: users,
    nextPageToken: nextPageToken,
    size: size,
    lastPage: lastPage
  })));
};
export var pushFollowers = function pushFollowers(state, payload) {
  return _objectSpread(_objectSpread({}, state), {}, {
    byUserName: pushByUserName(state, payload),
    byFollowers: pushByFollowers(state, payload)
  });
};

// REDUCERS
var pending = function pending(state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    fetching: true,
    fetched: false
  });
};
var fulfilled = function fulfilled(state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    byUserName: pushByUserName(state, action.payload),
    error: null,
    fetching: false,
    fetched: true
  });
};
var rejected = function rejected(state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    error: action.payload,
    fetching: false,
    fetched: false
  });
};
var fulfilledFollowers = function fulfilledFollowers(state, action) {
  return _objectSpread(_objectSpread({}, pushFollowers(state, action.payload)), {}, {
    error: null,
    fetching: false,
    fetched: true
  });
};
var clearFollowers = function clearFollowers(state, action) {
  var trackId = action.payload.trackId;
  return _objectSpread(_objectSpread({}, state), {}, {
    error: null,
    byFollowers: _objectSpread(_objectSpread({}, state.byFollowers), {}, _defineProperty({}, trackId, {
      users: [],
      page: 0,
      lastPage: false,
      size: 0
    }))
  });
};
var removeFollower = function removeFollower(state, action) {
  var _action$payload = action.payload,
    trackId = _action$payload.trackId,
    userName = _action$payload.userName;
  var byFollowers = state.byFollowers[trackId];
  var users = byFollowers && byFollowers.users ? byFollowers.users.filter(function (follower) {
    return follower !== userName.toLowerCase();
  }) : [];
  return _objectSpread(_objectSpread({}, state), {}, {
    byFollowers: _objectSpread(_objectSpread({}, state.byFollowers), {}, _defineProperty({}, trackId, _objectSpread(_objectSpread({}, byFollowers), {}, {
      users: users
    })))
  });
};
var setProp = function setProp(state, action) {
  var _action$payload2 = action.payload,
    userName = _action$payload2.userName,
    propName = _action$payload2.propName,
    propValue = _action$payload2.propValue;
  var user = state.byUserName[userName.toLowerCase()];
  if (user) {
    user[propName] = propValue;
  }
  return _objectSpread(_objectSpread({}, state), {}, {
    byUserName: _objectSpread(_objectSpread({}, state.byUserName), {}, _defineProperty({}, userName, user))
  });
};
export var reducer = createReducer(initialState, (_createReducer = {}, _defineProperty(_createReducer, types.FETCH_PENDING, pending), _defineProperty(_createReducer, types.FETCH_FULFILLED, fulfilled), _defineProperty(_createReducer, types.FETCH_REJECTED, rejected), _defineProperty(_createReducer, types.FETCH_FOLLOWERS_PENDING, pending), _defineProperty(_createReducer, types.FETCH_FOLLOWERS_FULFILLED, fulfilledFollowers), _defineProperty(_createReducer, types.FETCH_FOLLOWERS_REJECTED, rejected), _defineProperty(_createReducer, types.CLEAR_FOLLOWERS, clearFollowers), _defineProperty(_createReducer, types.REMOVE_FOLLOWER, removeFollower), _defineProperty(_createReducer, types.SET_USER_PROP, setProp), _createReducer));

// ACTIONS
export var hasPermission = function hasPermission(trackPermissions, action) {
  var permissions = trackPermissions || {};
  var actions = permissions.actions;
  if (actions) {
    return actions.indexOf(action) >= 0;
  }
  return false;
};
var getUserPath = function getUserPath(_ref, auth) {
  var id = _ref.id,
    userName = _ref.userName;
  var url = "".concat(endpoints.APIv1, "/users/");
  if (id) {
    return url + id;
  }
  if (userName) {
    return url + (auth ? 'secureUsername/' : 'username/') + userName;
  }
  return null;
};
export var fetchUser = function fetchUser(user, req) {
  var auth = IO.auth(req);
  var url = getUserPath(user, auth);
  return IO.get(url, null, auth).then(function (res) {
    return res.data;
  });
};
export var getUser = function getUser(user, req) {
  return function (dispatch) {
    var prom = fetchUser(user, req);
    if (dispatch) {
      dispatch({
        type: types.FETCH_PENDING
      });
      prom.then(function (data) {
        dispatch({
          type: types.FETCH_FULFILLED,
          payload: {
            data: data
          }
        });
      }).catch(function (err) {
        dispatch({
          type: types.FETCH_REJECTED,
          payload: err.response || err
        });
      });
    }
    return prom;
  };
};
export var getById = function getById(id) {
  if (!this.byUserName || !id) return null;
  for (var _userName2 in this.byUserName) {
    var _user = this.byUserName[_userName2];
    if (parseInt(_user.id) === parseInt(id)) {
      return _user;
    }
  }
};
export var changePassword = function changePassword(password) {
  return new Promise(function (resolve, reject) {
    var params = new window.URLSearchParams();
    params.append('password', password);
    params.append('confirmPassword', password);
    IO.put(endpoints.APIv1 + "/users/changePassword", params, {
      authenticated: true
    }).then(function (res) {
      resolve(res.response);
    }).catch(function (err) {
      reject(err.response);
    });
  });
};
export var editUser = function editUser(params) {
  return new Promise(function (resolve, reject) {
    IO.put(endpoints.APIv1 + "/users", params, {
      authenticated: true
    }).then(function (res) {
      resolve(res.response);
    }).catch(function (err) {
      reject(err.response);
    });
  });
};
export var checkUsername = function checkUsername(username) {
  return IO.get(endpoints.APIv1 + "/users/checkUsername", {
    username: username
  }).then(function (res) {
    return res.status;
  }).catch(function (err) {
    return err.response.status;
  });
};
export var registerUser = function registerUser(name, email, password, birthDate) {
  var endpoint = getParam('enable_register_v3') ? endpoints.APIv3 : endpoints.APIv2;
  return IO.post(endpoint + '/users/register', {
    name: name,
    email: email,
    password: password,
    birthDate: birthDate,
    securityFlow: true,
    locale: getLocale()
  }).then(function (res) {
    return res;
  }).catch(function (err) {
    return err.response;
  });
};
export var checkEmail = function checkEmail(email) {
  return IO.get(endpoints.APIv1 + "/users/checkEmail", {
    email: email
  }).then(function (res) {
    return res.status;
  }).catch(function (err) {
    return err.response.status;
  });
};
export var findUser = function findUser(userName, store) {
  var state = store.getState().users;
  return state.byUserName[userName];
};
export var getFollowersRequest = function getFollowersRequest(trackId, rows, nextPageToken) {
  var auth = IO.auth();
  var params = {
    rows: rows,
    nextPageToken: nextPageToken
  };
  var path = "/collections/".concat(trackId, "/followers");
  return IO.get(endpoints.APIv2 + path, params, auth).then(function (res) {
    return res.data;
  });
};
export var getFollowersByTrack = function getFollowersByTrack(trackId) {
  var rows = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 20;
  var page = arguments.length > 2 ? arguments[2] : undefined;
  return function (dispatch, getState) {
    dispatch({
      type: types.FETCH_FOLLOWERS_PENDING,
      payload: {
        trackId: trackId
      }
    });
    var request = getFollowersRequest(trackId, rows, page);
    return request.then(function (_ref2) {
      var nextPageToken = _ref2.nextPageToken,
        data = _ref2.data;
      dispatch({
        type: types.FETCH_FOLLOWERS_FULFILLED,
        payload: {
          trackId: trackId,
          data: data,
          nextPageToken: nextPageToken,
          rows: rows
        }
      });
      return data;
    }).catch(function (err) {
      dispatch({
        type: types.FETCH_FOLLOWERS_REJECTED,
        payload: err.response || err
      });
    });
  };
};
export var changeFollowerMemberType = function changeFollowerMemberType(trackId, user, memberType) {
  return function (dispatch) {
    return IO.put(endpoints.APIv1 + "/collections/".concat(trackId, "/members/").concat(user.id), {
      memberType: memberType
    }, {
      authenticated: true
    }).then(function () {
      setUserProp(user.userName, 'memberType', memberType)(dispatch);
    });
  };
};
export var banFollowerFromCommunity = function banFollowerFromCommunity(trackId, user) {
  return function (dispatch) {
    var params = new window.URLSearchParams();
    params.append('userId', user.id);
    return IO.put(endpoints.APIv1 + "/collections/".concat(trackId, "/blockFollower"), params, {
      authenticated: true
    }).then(function () {
      dispatch({
        type: types.REMOVE_FOLLOWER,
        payload: {
          trackId: trackId,
          userName: user.userName
        }
      });
    });
  };
};
export var removeFollowerFromCommunity = function removeFollowerFromCommunity(trackId, user) {
  return function (dispatch) {
    var params = new window.URLSearchParams();
    params.append('userId', user.id);
    return IO.put(endpoints.APIv1 + "/collections/".concat(trackId, "/deleteFollower"), params, {
      authenticated: true
    }).then(function () {
      dispatch({
        type: types.REMOVE_FOLLOWER,
        payload: {
          trackId: trackId,
          userName: user.userName
        }
      });
    });
  };
};
export var getTrendingUsers = function getTrendingUsers() {
  var rows = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 24;
  var page = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var req = arguments.length > 2 ? arguments[2] : undefined;
  return function (dispatch) {
    var auth = IO.auth(req);
    var authPath = auth ? 'recommendedFeed' : 'recommendedFeedPublic';
    dispatch({
      type: types.FETCH_PENDING,
      payload: 'trending'
    });
    return IO.get(endpoints.APIv1 + "/users/".concat(authPath, "?page=").concat(page, "&rows=").concat(rows), null, auth).then(function (res) {
      dispatch({
        type: types.FETCH_FOLLOWERS_FULFILLED,
        payload: {
          trackId: 'trending',
          data: res.data.data,
          page: page,
          rows: rows,
          size: res.data.size,
          sizeOf: 'trending'
        }
      });
    }).catch(function (err) {
      dispatch({
        type: types.FETCH_REJECTED,
        payload: err.reponse || err
      });
    });
  };
};
export var getSearchRequest = function getSearchRequest(searchTerm, rows, page) {
  var triesLeft = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
  var auth = IO.auth();
  return new Promise(function (resolve, reject) {
    IO.get(endpoints.APIv1 + "/users/search?page=".concat(page, "&rows=").concat(rows, "&q=").concat(encodeURIComponent(searchTerm)), null, auth).then(function (res) {
      return resolve(res);
    }).catch(function (err) {
      if (triesLeft > 0) {
        triesLeft--;
        getSearchRequest(searchTerm, rows, page, triesLeft).then(function (res) {
          return resolve(res);
        }).catch(function (err) {
          return reject(err);
        });
      } else {
        reject(err);
      }
    });
  });
};
export var searchUsers = function searchUsers(searchTerm) {
  var rows = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 24;
  var page = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  return function (dispatch) {
    var searchId = 'search';
    dispatch({
      type: types.FETCH_PENDING,
      payload: searchId
    });
    return getSearchRequest(searchTerm, rows, page, 3).then(function (res) {
      dispatch({
        type: types.FETCH_FOLLOWERS_FULFILLED,
        payload: {
          page: page,
          rows: rows,
          trackId: searchId,
          data: res.data.data,
          size: res.data.size,
          sizeOf: searchId
        }
      });
    }).catch(function (err) {
      dispatch({
        type: types.FETCH_REJECTED,
        payload: err.response || err
      });
    });
  };
};
export var clearUsersByTrack = function clearUsersByTrack(trackId) {
  return function (dispatch) {
    dispatch({
      type: types.CLEAR_FOLLOWERS,
      payload: {
        trackId: trackId
      }
    });
  };
};
export var setUserProp = function setUserProp(userName, propName, propValue) {
  return function (dispatch) {
    dispatch({
      type: types.SET_USER_PROP,
      payload: {
        userName: userName,
        propName: propName,
        propValue: propValue
      }
    });
  };
};
export var registerUserWithApple = function registerUserWithApple(token, name, email, birthDate) {
  return IO.post(endpoints.APIv1 + '/userSocialAccount/signup', {
    token: token,
    name: name,
    email: email,
    birthDate: birthDate,
    provider: 'apple'
  }).then(function (res) {
    return res;
  }).catch(function (err) {
    return err.response;
  });
};
export var bindApple = function bindApple(token) {
  var headers = {
    'Content-Type': 'application/x-www-form-urlencoded'
  };
  var params = new URLSearchParams();
  params.append('provider', 'apple');
  params.append('token', token);
  return IO.post(endpoints.APIv1 + '/userSocialAccount/bind', params, {
    authenticated: true,
    headers: headers
  });
};
export var unbindApple = function unbindApple() {
  return IO.delete(endpoints.APIv1 + '/userSocialAccount/unbind?provider=apple', null, {
    authenticated: true
  });
};
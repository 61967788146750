import _typeof from "/drone/src/node_modules/next/node_modules/@babel/runtime/helpers/esm/typeof";
import _toConsumableArray from "/drone/src/node_modules/next/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "/drone/src/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
var _createReducer;
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import IO from "../utils/IO";
import Auth from "../utils/Auth";
import Uploader from "../utils/Uploader";
import randomComment from "../utils/regex/randomComment";
import { endpoints } from "../utils/config";
import { getFileType } from "../utils/media";
import { hasPermission } from "./sparks";
import { sortBy, unique, guid } from "../utils/_";
import { normalizeComment } from "../utils/normalizer";
import { createReducer } from "../utils/redux";
import { types as threadTypes } from "./thread";
export var COMMENTS_PER_PAGE = 10;
export var initialState = {
  bySpark: {},
  byReplies: {},
  perPage: COMMENTS_PER_PAGE
};
export var initialCommentState = {
  byDate: [],
  byCreation: [],
  page: [],
  size: null,
  fetching: false,
  fetched: false,
  error: null
};
export var types = {
  FETCH: 'comments/FETCH',
  FETCH_FULFILLED: 'comments/FETCH_FULFILLED',
  FETCH_REJECTED: 'comments/FETCH_REJECTED',
  FETCH_PENDING: 'comments/FETCH_PENDING',
  CREATE: 'comments/CREATE',
  CREATE_PENDING: 'comments/CREATE_PENDING',
  CREATE_FULFILLED: 'comments/CREATE_FULFILLED',
  CREATE_REJECTED: 'comments/CREATE_REJECTED',
  DELETE_FULFILLED: 'comments/DELETE_FULFILLED'
};
export var commentTypes = ['text', 'audio', 'video', 'image', 'sortition', 'url', 'document'];
var getPageArray = function getPageArray(newPage) {
  var oldPages = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var onlyPage = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var pagesSet = Number.isInteger(oldPages[0]) && Number.isInteger(oldPages[1]);
  if (pagesSet && !onlyPage) {
    var _pageOlder = newPage < oldPages[0] ? newPage : oldPages[0];
    var _pageNewer = newPage > oldPages[1] ? newPage : oldPages[1];
    return [_pageOlder, _pageNewer];
  } else {
    return [newPage, newPage];
  }
};
var getFetchStateData = function getFetchStateData(state, action) {
  var _action$payload = action.payload,
    sparkId = _action$payload.sparkId,
    commentId = _action$payload.commentId;
  var stateType = commentId ? 'byReplies' : 'bySpark';
  var stateEl = commentId || sparkId;
  var commentState = state[stateType][stateEl] || initialCommentState;
  return {
    stateType: stateType,
    stateEl: stateEl,
    commentState: commentState
  };
};
var getCreationStateData = function getCreationStateData(state, action) {
  var _action$payload2 = action.payload,
    sparkId = _action$payload2.sparkId,
    comment = _action$payload2.comment,
    threadOpen = _action$payload2.threadOpen;
  var repliedCommentId = comment.repliedCommentId;
  var isReply = threadOpen && repliedCommentId;
  var stateType = isReply ? 'byReplies' : 'bySpark';
  var stateEl = isReply ? repliedCommentId : sparkId;
  var commentState = state[stateType][stateEl] || initialCommentState;
  return {
    stateType: stateType,
    stateEl: stateEl,
    commentState: commentState,
    isReply: isReply
  };
};
var removeComment = function removeComment(arr, commentId) {
  return arr.filter(function (el) {
    return el.id !== commentId;
  });
};
var removeCommentFrom = function removeCommentFrom(state, action, entity) {
  var _action$payload3 = action.payload,
    sparkId = _action$payload3.sparkId,
    commentId = _action$payload3.commentId,
    repliedCommentId = _action$payload3.repliedCommentId;
  var stateBy = state[entity];
  var entityId = entity === 'byReplies' ? repliedCommentId : sparkId;
  var entityState = stateBy[entityId];
  if (entityState) {
    return _objectSpread(_objectSpread({}, stateBy), {}, _defineProperty({}, entityId, _objectSpread(_objectSpread({}, entityState), {}, {
      byDate: removeComment(entityState.byDate, commentId)
    })));
  } else {
    return _objectSpread({}, stateBy);
  }
};

// REDUCERS
var pending = function pending(state, action) {
  var _getFetchStateData = getFetchStateData(state, action),
    stateType = _getFetchStateData.stateType,
    stateEl = _getFetchStateData.stateEl,
    commentState = _getFetchStateData.commentState;
  return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, stateType, _objectSpread(_objectSpread({}, state[stateType]), {}, _defineProperty({}, stateEl, _objectSpread(_objectSpread({}, commentState), {}, {
    fetching: true
  })))));
};
var fulfilled = function fulfilled(state, action) {
  var data = action.payload.data;
  var _getFetchStateData2 = getFetchStateData(state, action),
    stateType = _getFetchStateData2.stateType,
    stateEl = _getFetchStateData2.stateEl,
    _getFetchStateData2$c = _getFetchStateData2.commentState,
    commentState = _getFetchStateData2$c === void 0 ? {} : _getFetchStateData2$c;
  var newComments = unique([].concat(_toConsumableArray(commentState.byDate), _toConsumableArray(data.data)), 'id');
  var onlyPage = newComments.length <= COMMENTS_PER_PAGE;
  return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, stateType, _objectSpread(_objectSpread({}, state[stateType]), {}, _defineProperty({}, stateEl, _objectSpread(_objectSpread({}, commentState), {}, {
    byDate: sortBy(newComments, 'date'),
    page: getPageArray(data.page, commentState.page, onlyPage),
    size: data.size,
    fetching: false,
    fetched: true
  })))));
};
var rejected = function rejected(state, action) {
  var error = action.payload.error;
  var _getFetchStateData3 = getFetchStateData(state, action),
    stateType = _getFetchStateData3.stateType,
    stateEl = _getFetchStateData3.stateEl,
    commentState = _getFetchStateData3.commentState;
  return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, stateType, _objectSpread(_objectSpread({}, state[stateType]), {}, _defineProperty({}, stateEl, _objectSpread(_objectSpread({}, commentState), {}, {
    error: error,
    fetching: false
  })))));
};
var creating = function creating(state, action) {
  var comment = action.payload.comment;
  var _getCreationStateData = getCreationStateData(state, action),
    commentState = _getCreationStateData.commentState,
    stateType = _getCreationStateData.stateType,
    stateEl = _getCreationStateData.stateEl;
  var byCreation = [].concat(_toConsumableArray(commentState.byCreation), [comment]);
  return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, stateType, _objectSpread(_objectSpread({}, state[stateType]), {}, _defineProperty({}, stateEl, _objectSpread(_objectSpread({}, commentState), {}, {
    byCreation: byCreation
  })))));
};
var created = function created(state, action) {
  var comment = action.payload.comment;
  var _getCreationStateData2 = getCreationStateData(state, action),
    commentState = _getCreationStateData2.commentState,
    stateType = _getCreationStateData2.stateType,
    stateEl = _getCreationStateData2.stateEl;
  var byCreation = commentState.byCreation.filter(function (el) {
    return el.pubDate !== comment.pubDate;
  });
  return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, stateType, _objectSpread(_objectSpread({}, state[stateType]), {}, _defineProperty({}, stateEl, _objectSpread(_objectSpread({}, commentState), {}, {
    byCreation: byCreation
  })))));
};
var createError = function createError(state, action) {
  var _action$payload4 = action.payload,
    comment = _action$payload4.comment,
    error = _action$payload4.error;
  var _getCreationStateData3 = getCreationStateData(state, action),
    commentState = _getCreationStateData3.commentState,
    stateType = _getCreationStateData3.stateType,
    stateEl = _getCreationStateData3.stateEl;
  var byCreation = commentState.byCreation.map(function (el) {
    if (el.pubDate === comment.pubDate) {
      el.error = error;
    }
    return el;
  });
  return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, stateType, _objectSpread(_objectSpread({}, state[stateType]), {}, _defineProperty({}, stateEl, _objectSpread(_objectSpread({}, commentState), {}, {
    byCreation: byCreation
  })))));
};
var deleted = function deleted(state, action) {
  var bySpark = removeCommentFrom(state, action, 'bySpark');
  var byReplies = removeCommentFrom(state, action, 'byReplies');
  return _objectSpread(_objectSpread({}, state), {}, {
    bySpark: bySpark,
    byReplies: byReplies
  });
};
export var reducer = createReducer(initialState, (_createReducer = {}, _defineProperty(_createReducer, types.FETCH_PENDING, pending), _defineProperty(_createReducer, types.FETCH_FULFILLED, fulfilled), _defineProperty(_createReducer, types.FETCH_REJECTED, rejected), _defineProperty(_createReducer, types.CREATE_PENDING, creating), _defineProperty(_createReducer, types.CREATE_FULFILLED, created), _defineProperty(_createReducer, types.CREATE_REJECTED, createError), _defineProperty(_createReducer, types.DELETE_FULFILLED, deleted), _createReducer));

// ACTIONS
var fetchComments = function fetchComments(path, params, payload) {
  return function (dispatch, getState) {
    var auth = IO.auth();
    dispatch({
      type: types.FETCH_PENDING,
      payload: payload
    });
    return IO.get(path, params, auth).then(function (res) {
      payload.data = res.data;
      dispatch({
        type: types.FETCH_FULFILLED,
        payload: payload
      });
      return payload;
    }).catch(function (err) {
      payload.error = err.response;
      dispatch({
        type: types.FETCH_REJECTED,
        payload: payload
      });
    });
  };
};
var updateThreadState = function updateThreadState(trigger) {
  return function (dispatch, getState) {
    var _getState = getState(),
      thread = _getState.thread;
    var threadData = thread && thread.data || {};
    var repliedComment = trigger && trigger.data && trigger.data.repliedComment || {};
    var message = repliedComment.message,
      authorMemberType = repliedComment.authorMemberType;
    if (message) {
      var _comment = _objectSpread(_objectSpread({}, threadData && threadData.comment), {}, {
        message: message,
        authorMemberType: authorMemberType
      });
      var _payload = {
        data: _objectSpread(_objectSpread({}, threadData), {}, {
          comment: _comment
        })
      };
      dispatch({
        type: threadTypes.SET_THREAD,
        payload: _payload
      });
    }
  };
};
export var getComments = function getComments(sparkId, trackId) {
  var page = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : -1;
  return function (dispatch, getState) {
    var auth = IO.auth();
    var authPath = auth ? '/secure' : '';
    var payload = {
      sparkId: sparkId
    };
    var path = endpoints.APIv2 + "/news/".concat(sparkId, "/comments").concat(authPath);
    var lastPage = page === -1;
    var params = {
      lastPage: lastPage,
      page: page,
      rows: COMMENTS_PER_PAGE,
      lastViewed: auth && lastPage,
      includeUnprocessed: Boolean(auth),
      collectionId: trackId && trackId !== 'trending' && trackId !== 'feed' ? trackId : null
    };
    return fetchComments(path, params, payload)(dispatch);
  };
};
export var getReplies = function getReplies(commentId, sparkId, trackId) {
  var page = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : -1;
  return function (dispatch, getState) {
    var auth = IO.auth();
    var authPath = auth ? 'repliesSecure' : 'replies';
    var payload = {
      commentId: commentId
    };
    var path = endpoints.APIv2 + "/news/".concat(sparkId, "/comments/").concat(commentId, "/").concat(authPath);
    var lastPage = page === -1;
    var params = {
      lastPage: lastPage,
      page: page,
      rows: COMMENTS_PER_PAGE,
      includeUnprocessed: Boolean(auth),
      lastViewed: auth && lastPage,
      collectionId: trackId
    };
    var threadPromise = fetchComments(path, params, payload)(dispatch);
    threadPromise.then(function (trigger) {
      updateThreadState(trigger)(dispatch, getState);
    });
    return threadPromise;
  };
};
var buildNewComment = function buildNewComment(spark, data, session) {
  var message = data.message,
    repliedCommentId = data.repliedCommentId,
    repliedComment = data.repliedComment,
    file = data.file,
    src = data.src,
    title = data.title,
    link = data.link,
    imageUrl = data.imageUrl,
    domainUrl = data.domainUrl,
    domainImage = data.domainImage;
  var loggedUser = session.user;
  var newComment = {
    message: message,
    repliedCommentId: repliedCommentId,
    repliedComment: _objectSpread(_objectSpread({}, repliedComment), {}, {
      authorImagePath: null,
      authorHasImage: false
    }),
    file: file,
    src: src,
    title: title,
    link: link,
    imageUrl: imageUrl,
    domainUrl: domainUrl,
    domainImage: domainImage,
    fileName: file ? file.name : null,
    contentLength: file ? file.contentLength || file.size : 0,
    type: setCommentType(data, spark),
    pubDate: new Date().getTime(),
    uuid: guid()
  };
  return normalizeComment(newComment, loggedUser);
};
var getCommentCreationData = function getCommentCreationData(spark, trackId, data) {
  return function (dispatch, getState) {
    var _getState2 = getState(),
      session = _getState2.session,
      thread = _getState2.thread;
    var sparkId = spark.id;
    var threadOpen = thread.open;
    var comment = buildNewComment(spark, data, session);
    var repliedCommentId = data.repliedCommentId;
    var payload = {
      sparkId: sparkId,
      comment: comment,
      threadOpen: threadOpen,
      commentId: repliedCommentId
    };
    var hasMedia = Boolean(data.file);
    return {
      trackId: trackId,
      sparkId: sparkId,
      threadOpen: threadOpen,
      comment: comment,
      repliedCommentId: repliedCommentId,
      hasMedia: hasMedia,
      payload: payload
    };
  };
};
var setMediaKeyToCreationData = function setMediaKeyToCreationData(creationData, mediaKey) {
  return _objectSpread(_objectSpread({}, creationData), {}, {
    comment: _objectSpread(_objectSpread({}, creationData.comment), {}, {
      mediaKey: mediaKey
    })
  });
};
var uploadCommentMedia = function uploadCommentMedia(creationData) {
  var comment = creationData.comment;
  return new Promise(function (resolve, reject) {
    var file = comment.file || {};
    var onSuccess = function onSuccess(res) {
      var mediaKey = res.Key || res.key;
      var newCreationData = setMediaKeyToCreationData(creationData, mediaKey);
      resolve(newCreationData);
    };
    var callbacks = {
      onSuccess: onSuccess,
      onFailure: reject,
      onStart: null,
      onProgress: null
    };
    Uploader.send(file, callbacks, 'spark/spark_comment', {
      includeExtension: comment.type === 1 // Only for audio comments
    });
  });
};

var postComment = function postComment(creationData) {
  return function (dispatch, getState) {
    var payload = creationData.payload,
      sparkId = creationData.sparkId,
      comment = creationData.comment;
    return IO.post(endpoints.APIv2 + "/news/".concat(sparkId, "/comments"), comment, {
      authenticated: true
    }).then(function (res) {
      if (res.status === 204) {
        dispatch({
          type: types.CREATE_REJECTED,
          payload: _objectSpread(_objectSpread({}, payload), {}, {
            error: 'NO_CONTENT'
          })
        });
      } else {
        var _sparkId = creationData.sparkId,
          repliedCommentId = creationData.repliedCommentId,
          threadOpen = creationData.threadOpen,
          trackId = creationData.trackId;
        var _createFulfilled = function _createFulfilled() {
          dispatch({
            type: types.CREATE_FULFILLED,
            payload: payload
          });
        };
        var _commentPromise = getComments(_sparkId, trackId)(dispatch);
        if (repliedCommentId && threadOpen) {
          var _threadPromise = getReplies(repliedCommentId, _sparkId, trackId)(dispatch, getState);
          _threadPromise.then(_createFulfilled);
        } else {
          _commentPromise.then(_createFulfilled);
        }
      }
      return res.data;
    }).catch(function (err) {
      dispatch({
        type: types.CREATE_REJECTED,
        payload: _objectSpread(_objectSpread({}, payload), {}, {
          error: err.response
        })
      });
    });
  };
};
export var createComment = function createComment(spark, trackId, data) {
  return function (dispatch, getState) {
    var creationData = getCommentCreationData(spark, trackId, data)(dispatch, getState);
    var payload = creationData.payload,
      hasMedia = creationData.hasMedia;
    dispatch({
      type: types.CREATE_PENDING,
      payload: payload
    });
    if (hasMedia) {
      return uploadCommentMedia(creationData).then(function (newCreationData) {
        return postComment(newCreationData)(dispatch, getState);
      });
    }
    return postComment(creationData)(dispatch, getState);
  };
};
export var deleteComment = function deleteComment(sparkId, commentId, repliedCommentId) {
  return function (dispatch, getState) {
    var payload = {
      sparkId: sparkId,
      commentId: commentId,
      repliedCommentId: repliedCommentId
    };
    return IO.delete(endpoints.APIv1 + "/news/".concat(sparkId, "/comments/").concat(commentId), null, {
      authenticated: true
    }).then(function (res) {
      dispatch({
        type: types.DELETE_FULFILLED,
        payload: payload
      });
    });
  };
};
export var readComment = function readComment(sparkId, commentId) {
  return IO.put(endpoints.APIv1 + "/news/".concat(sparkId, "/comments/").concat(commentId, "/read"), null, {
    authenticated: true
  }).then(function (res) {
    return res.data;
  });
};
export var getSortition = function getSortition(sortitionId) {
  var rows = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 100;
  var page = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  var params = {
    rows: rows,
    page: page
  };
  return IO.get(endpoints.APIv1 + "/sortition/comment/".concat(sortitionId), params, {
    authenticated: true
  }).then(function (res) {
    return res.data;
  });
};
export var isAuthor = function isAuthor(authorId, userId) {
  if (userId) {
    return authorId === userId;
  }
  var me = Auth.getUser();
  if (me) {
    return authorId === me.id;
  }
  return false;
};
export var setCommentType = function setCommentType(comment, spark) {
  var message = comment.message,
    file = comment.file,
    link = comment.link;
  if (file) {
    var _fileType = getFileType(file);
    return commentTypes.indexOf(_fileType);
  }
  if (link) {
    return commentTypes.indexOf('url');
  }
  var isRandomComment = randomComment().test(message.trim());
  var canCreateRandom = spark ? hasPermission(spark.ownerCollectionPermissions, 'COMMENT_SORTITION_CREATE') : true;
  if (isRandomComment && canCreateRandom) {
    return commentTypes.indexOf('sortition');
  }
  return commentTypes.indexOf('text');
};
export var getCommentType = function getCommentType(param) {
  var isObjectParam = _typeof(param) === 'object' && Boolean(param.type);
  var type = isObjectParam ? param.type : param;
  return commentTypes[type];
};
export var getById = function getById(state, commentId, sparkId) {
  var stateType = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'bySpark';
  var arr = state[stateType][sparkId];
  return arr && arr.byDate.length && arr.byDate.find(function (comment) {
    return comment.id === commentId;
  });
};
var API = 'https://api-sparkle.buildstaging.com';
var LIVE_API = 'https://api-live-admin.buildstaging.com';
var SECURITY_API = 'https://api-sparkle-security.buildstaging.com';
var HUB = {
  purchases: 'https://hot-hub-ui-stg.buildstaging.com/purchase'
};
var HOTPAY = {
  checkoutUrl: 'https://app-checkout-vue.buildstaging.com/assets/js/hotmart.transparent_checkout.js'
};
var VULCANO = {
  dashboard: 'https://app-vulcano.buildstaging.com'
};
var PLAYER = {
  embed: 'https://app-player-embed.buildstaging.com/embed'
};
var CAS = {
  signup: 'https://sso.buildstaging.com/signup'
};
var LIVE = {
  admin: 'https://app-live-admin.buildstaging.com',
  user: 'https://app-live-user.buildstaging.com',
  manager: 'https://api-live-manager.buildstaging.com',
  API: LIVE_API,
  APIv1: "".concat(LIVE_API, "/v1")
};
var SECURITY = {
  API: SECURITY_API,
  APIv1: "".concat(SECURITY_API, "/api/v1")
};
var endpoints = {
  API: API,
  LIVE: LIVE,
  HUB: HUB,
  HOTPAY: HOTPAY,
  VULCANO: VULCANO,
  PLAYER: PLAYER,
  CAS: CAS,
  SECURITY: SECURITY,
  APIv1: API + '/rest/v1',
  APIv2: API + '/rest/v2',
  APIv3: API + '/rest/v3',
  APP: 'https://buildstaging.com',
  FORGOT_PASSWORD: 'https://app-vulcano.buildstaging.com/password/forgot'
};
var cookie = {
  domain: 'buildstaging.com',
  subdomain: 'sparkle-app'
};
var s3 = {
  region: 'us-east-1',
  cognitoRegion: 'us-east-1',
  apiVersion: '2006-03-01',
  Bucket: 'staging-content.sparkle',
  IdentityPoolId: 'us-east-1:11897e12-765d-4f58-a739-554d35dcec77'
};
var HOTJAR_ID = 2560537;
module.exports = {
  cookie: cookie,
  endpoints: endpoints,
  s3: s3,
  HOTJAR_ID: HOTJAR_ID
};
var API = 'https://api.sparkleapp.com.br';
var LIVE_API = 'https://api-live-admin.play.hotmart.com';
var SECURITY_API = 'https://api-sparkle-security.spk.hotmart.com';
var HUB = {
  purchases: 'https://consumer.hotmart.com/purchase?itm_medium=internal&itm_source=hotmart&itm_campaign=all_traffic_first-access_button_hotmart_communities_old_products'
};
var HOTPAY = {
  checkoutUrl: 'https://pay.hotmart.com/assets/js/hotmart.transparent_checkout.js'
};
var VULCANO = {
  dashboard: 'https://app-vlc.hotmart.com'
};
var PLAYER = {
  embed: 'https://player.hotmart.com/embed'
};
var CAS = {
  signup: 'https://sso.hotmart.com/signup'
};
var LIVE = {
  admin: 'https://live-admin.play.hotmart.com',
  user: 'https://webinar.play.hotmart.com',
  manager: 'https://api-live-manager.hotmart.com',
  API: LIVE_API,
  APIv1: "".concat(LIVE_API, "/v1")
};
var SECURITY = {
  API: SECURITY_API,
  APIv1: "".concat(SECURITY_API, "/api/v1")
};
var endpoints = {
  API: API,
  LIVE: LIVE,
  HUB: HUB,
  HOTPAY: HOTPAY,
  VULCANO: VULCANO,
  PLAYER: PLAYER,
  CAS: CAS,
  SECURITY: SECURITY,
  APIv1: API + '/rest/v1',
  APIv2: API + '/rest/v2',
  APIv3: API + '/rest/v3',
  APP: 'https://hotmart.com',
  FORGOT_PASSWORD: 'https://app-vlc.hotmart.com/password/forgot'
};
var cookie = {
  domain: 'hotmart.com',
  subdomain: 'sparkle'
};
var HOTJAR_ID = 1655176;
var s3 = {
  region: 'us-east-1',
  cognitoRegion: 'us-east-2',
  apiVersion: '2006-03-01',
  Bucket: 'content.hotmedia',
  IdentityPoolId: 'us-east-2:12327cb6-54f9-46c0-b46f-a667dd583298'
};
module.exports = {
  HOTJAR_ID: HOTJAR_ID,
  cookie: cookie,
  endpoints: endpoints,
  s3: s3
};
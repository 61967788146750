import _defineProperty from "/drone/src/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { fontStyle } from "../mixins";
var defaultColors = {
  white: '#FFFFFF',
  gray20: '#F4F7FC',
  gray40: '#D8D8D8',
  gray60: '#828F95',
  gray70: '#32363B',
  gray80: '#323040',
  black: '#000000',
  primary: '#FF5533',
  success: '#54D29C',
  error: '#F57256',
  warning: '#F5B756',
  info: '#6696E5',
  link: '#0070E0'
};
var additionalColors = {
  primaryLight: '#F0674C',
  primaryDark: '#F0674C',
  secondary: '#13CEDA',
  neon: '#10FFF8',
  youtube: '#FF0000',
  green: '#2ecc71',
  greenLight: '#28E277'
};
export var colors = _objectSpread(_objectSpread({}, defaultColors), additionalColors);
export var fonts = {
  B64: fontStyle(64, 700, colors.gray80),
  B24: fontStyle(24, 700, colors.gray80),
  B18: fontStyle(18, 700, colors.gray80),
  B16: fontStyle(16, 700, colors.gray80),
  B14: fontStyle(14, 700, colors.gray80),
  B12: fontStyle(12, 700, colors.gray80),
  B11: fontStyle(11, 700, colors.gray80),
  B10: fontStyle(10, 700, colors.gray60),
  R18: fontStyle(18, 400, colors.gray60),
  R16: fontStyle(16, 400, colors.gray60),
  R14: fontStyle(14, 400, colors.gray60),
  R12: fontStyle(12, 400, colors.gray60),
  R11: fontStyle(11, 400, colors.gray60),
  R10: fontStyle(10, 400, colors.gray60)
};
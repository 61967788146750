import _defineProperty from "/drone/src/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
var _createReducer;
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { createReducer } from "../../../utils/redux";
import { initialState, types, STATUS } from "./constants";
import { createStatusProps } from "./utils";
import { pushBy } from "../../../utils/_";
var pushData = function pushData(state, payload) {
  var data = payload.data,
    status = payload.status,
    size = payload.size;
  var output = _objectSpread(_objectSpread({}, state), {}, {
    byId: _objectSpread({}, state.byId),
    byStatus: _objectSpread({}, state.byStatus)
  });
  if (!output.byStatus[status]) {
    output.byStatus[status] = {
      items: [],
      size: size || 0
    };
  }
  if (data && data.length > 0) {
    data.map(function (live) {
      var id = live.id;
      output.byId[id] = live;
      if (status) {
        output.byStatus[status] = {
          items: pushBy(output.byStatus[status].items, id),
          size: size
        };
      }
    });
  }
  return output;
};
var pending = function pending(state, action) {
  var status = action.payload.status;
  var statusProps = createStatusProps(status, {
    fetching: true,
    fetched: false,
    error: null
  });
  return _objectSpread(_objectSpread({}, state), statusProps);
};
var fulfilled = function fulfilled(state, action) {
  var status = action.payload.status;
  var statusProps = createStatusProps(status, {
    fetching: false,
    fetched: true
  });
  return _objectSpread(_objectSpread({}, pushData(state, action.payload)), statusProps);
};
var rejected = function rejected(state, action) {
  var _action$payload = action.payload,
    status = _action$payload.status,
    error = _action$payload.error;
  var statusProps = createStatusProps(status, {
    fetching: false,
    fetched: false,
    error: error
  });
  return _objectSpread(_objectSpread({}, state), statusProps);
};
var cancelLive = function cancelLive(state, action) {
  var id = action.payload.id;
  var output = _objectSpread(_objectSpread({}, state), {}, {
    byId: _objectSpread({}, state.byId),
    byStatus: _objectSpread({}, state.byStatus)
  });
  delete output.byId[id];
  var _output$byStatus$STAT = output.byStatus[STATUS.Created],
    _output$byStatus$STAT2 = _output$byStatus$STAT.items,
    prevItems = _output$byStatus$STAT2 === void 0 ? [] : _output$byStatus$STAT2,
    _output$byStatus$STAT3 = _output$byStatus$STAT.size,
    prevSize = _output$byStatus$STAT3 === void 0 ? 0 : _output$byStatus$STAT3;
  var nextItems, nextSize;
  nextItems = prevItems.filter(function (liveId) {
    return liveId !== id;
  });
  if (nextItems.length !== prevItems.length) {
    nextSize = prevSize - 1;
  }
  output.byStatus[STATUS.Created] = {
    items: nextItems,
    size: nextSize
  };
  return output;
};
var clearLives = function clearLives(state, action) {
  var status = action.payload.status;
  var statusProps = createStatusProps(status, {
    fetching: false,
    fetched: false,
    error: null
  });
  return _objectSpread(_objectSpread({}, state), {}, {
    byStatus: _objectSpread(_objectSpread({}, state.byStatus), {}, _defineProperty({}, status, {
      items: [],
      size: 0
    }))
  }, statusProps);
};
export var reducer = createReducer(initialState, (_createReducer = {}, _defineProperty(_createReducer, types.FETCH_PENDING, pending), _defineProperty(_createReducer, types.FETCH_FULFILLED, fulfilled), _defineProperty(_createReducer, types.FETCH_REJECTED, rejected), _defineProperty(_createReducer, types.CLEAR_LIVES, clearLives), _defineProperty(_createReducer, types.CANCEL_LIVE, cancelLive), _createReducer));
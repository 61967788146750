import IO from "./IO";
import regexUtm from "./regex/utm";
import regexProtocol from "./regex/protocol";
import { Router } from "./routes";
import { endpoints } from "./config";
import { RoutesPaths, basePath } from "../../shared/constants/RoutesPaths";
import { getCurrentLang } from "./i18n";
export var getDomain = function getDomain(url) {
  var cleanUrl = removeProtocol(url.trim());
  var stopAt = cleanUrl.indexOf('/');
  if (stopAt > 0) {
    return cleanUrl.substring(0, stopAt);
  }
  return cleanUrl;
};
export var removeUtmTags = function removeUtmTags(str) {
  var output = str.replace(regexUtm(), '');
  var lastPos = output.length - 1;
  var lastChar = output.charAt(lastPos);
  if (lastChar === '?') {
    output = str.substring(0, lastPos);
  }
  return output;
};
export var removeProtocol = function removeProtocol(url) {
  var protocol = url.match(regexProtocol());
  if (protocol) {
    var _str = protocol[0];
    var _startAt = url.indexOf(_str);
    var _before = url.substring(0, _startAt);
    var _after = url.substring(_startAt + _str.length);
    return _before + _after;
  }
  return url;
};
export var getFullUrl = function getFullUrl(req) {
  if (req) {
    return req.protocol + '://' + req.get('host') + req.originalUrl;
  }
  if (process.browser) {
    return window && window.location.href;
  }
  return null;
};
export var authMediaUrl = function authMediaUrl(url) {
  if (url) {
    return "".concat(endpoints.APP, "/").concat(getCurrentLang()).concat(basePath, "/authMedia/").concat(encodeURIComponent(url));
  }
  return url;
};
export var goTo = function goTo(path, res) {
  var forceReload = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  // Prevent Javascript injection
  if (path.startsWith('javascript:')) {
    window.location.href = RoutesPaths.HOME;
  } else if (res) {
    res.writeHead(302, {
      Location: path
    });
    res.end();
  } else if (process.browser) {
    var _isExternalUrl = regexProtocol().test(path);
    if (_isExternalUrl || forceReload) {
      window.location.href = path;
    } else {
      Router.pushRoute(path);
    }
  }
};
export var goToNewTab = function goToNewTab(url) {
  // Prevent Javascript injection
  if (url.startsWith('javascript:')) {
    window.location.href = RoutesPaths.HOME;
  } else {
    window.open(url, '_blank');
  }
};
export var isActive = function isActive(route) {
  var prefix = route.charAt(0) === '/' ? '' : '/';
  return prefix + route === Router.route;
};
export var addTrailingSlash = function addTrailingSlash(url) {
  if (!url) return '';
  if (url.slice(-1) !== '/') {
    return "".concat(url, "/");
  }
  return url;
};
export var removeTrailingSlash = function removeTrailingSlash(url) {
  if (!url) return '';
  if (url.slice(-1) === '/') {
    return url.slice(0, -1);
  }
  return url;
};
export var getMSUrlData = function getMSUrlData(url) {
  var timeout = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  return IO.get("".concat(window.location.origin, "/").concat(getCurrentLang()).concat(basePath, "/meta"), {
    url: url
  }, {
    timeout: timeout
  }).then(function (res) {
    var _res$data = res.data,
      url = _res$data.url,
      title = _res$data.title,
      description = _res$data.description,
      image = _res$data.image,
      logo = _res$data.logo;
    return {
      url: url || '',
      title: title || '',
      description: description || '',
      image: image || '',
      logo: logo || ''
    };
  }).catch(function () {
    return null;
  });
};
export var getUrlData = function getUrlData(url) {
  var auth = IO.auth();
  return IO.get(endpoints.APIv1 + "/news/preview", {
    url: url
  }, auth).then(function (res) {
    var _res$data2 = res.data,
      link = _res$data2.link,
      title = _res$data2.title,
      description = _res$data2.description,
      image = _res$data2.image,
      domainImage = _res$data2.domainImage;
    return {
      url: link || url || '',
      title: title || '',
      description: description || '',
      image: image || '',
      logo: domainImage || ''
    };
  }).catch(function (err) {
    return err.response.status;
  });
};
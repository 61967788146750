var _defineProperty = require("/drone/src/node_modules/next/node_modules/@babel/runtime/helpers/defineProperty");
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
var path = require('path');
var APP_PATH = path.resolve(__dirname, '../../../');
var APP_VERSION = require("../../../../package.json").version;
var ENV = process.env.APP_ENV || 'production';
var port = process.env.NODE_PORT || 3000;
var dev = process.env.APP_ENV === 'development';
var config = require('./' + ENV);
var social = {
  facebook: '//www.facebook.com/HotmartSparkle',
  instagram: '//www.instagram.com/hotmartsparkle',
  twitter: '//twitter.com/hotmart',
  youtube: '//www.youtube.com/user/HotmartPlataforma'
};
var store = {
  ios: '//itunes.apple.com/br/app/hotmart/id1066849827?ls=1&mt=8',
  android: '//play.google.com/store/apps/details?id=com.hotmart.sparkle'
};
var support = {
  email: "support@sparkle.com.br"
};
var sparkle = {
  blog: 'http://blog.sparkleapp.com.br',
  landing: 'https://hotmart.com/sparkle',
  faq: 'https://atendimento.hotmart.com.br/hc/pt-br/categories/360002523332-Hotmart-Sparkle',
  tos: 'https://hotmart.com/pt-br/legal',
  privacyPolicy: 'https://hotmart.com/pt-br/legal/privacidade-de-dados',
  cookiesPolicy: 'https://hotmart.com/pt-br/legal/politica-de-cookies'
};
var hotmart = {
  createProduct: process.env.APP_ENV === 'production' ? 'https://app.hotmart.com/products/add' : 'https://app.buildstaging.com/products/add',
  createMembership: process.env.APP_ENV === 'production' ? 'https://app.hotmart.com/membership/create' : 'https://app.buildstaging.com/membership/create'
};
var cas_client_id = 'ec0abe0b-5b2f-435a-80aa-113a2abbbba4';
var getDevice = function getDevice() {
  var ua = process.browser && navigator ? navigator.userAgent : null;
  if (ua) {
    if (ua.indexOf('Android') !== -1) return 'android';
    if (ua.indexOf('iPhone') !== -1 || ua.indexOf('iPad') !== -1) return 'ios';
    return 'desktop';
  }
  return null;
};
var getDeviceUrl = function getDeviceUrl() {
  var device = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : getDevice();
  return store[device];
};
module.exports = _objectSpread(_objectSpread({}, config), {}, {
  ENV: ENV,
  APP_PATH: APP_PATH,
  APP_VERSION: APP_VERSION,
  port: port,
  dev: dev,
  store: store,
  social: social,
  getDevice: getDevice,
  getDeviceUrl: getDeviceUrl,
  support: support,
  sparkle: sparkle,
  hotmart: hotmart,
  cas_client_id: cas_client_id
});
import _defineProperty from "/drone/src/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { fontStyle } from "../mixins";
var defaultColors = {
  white: '#FFFFFF',
  gray20: '#F0F3F7',
  gray30: '#E8E9ED',
  gray40: '#D0D3D6',
  gray60: '#6B7480',
  gray70: '#32363B',
  gray80: '#353A40',
  black: '#000000',
  primary: '#EF4E23',
  success: '#0FC75E',
  error: '#D6332C',
  warning: '#EFBA0F',
  info: '#1174D3',
  link: '#368EE2'
};
var additionalColors = {
  primaryDark: '#C5310A',
  primaryLight: '#FE734F',
  primaryLighter: '#FF9377',
  secondary: '#13CEDA',
  neon: '#10FFF8',
  youtube: '#FF0000',
  live: '#d6342c',
  green: '#2ecc71',
  greenLight: '#28E277',
  greenTranslucent: '#DEF8E9'
};
export var colors = _objectSpread(_objectSpread({}, defaultColors), additionalColors);
export var fonts = {
  B64: fontStyle(64, 700, colors.gray80),
  B24: fontStyle(24, 700, colors.gray80),
  B18: fontStyle(18, 700, colors.gray80),
  B16: fontStyle(16, 700, colors.gray80),
  B14: fontStyle(14, 700, colors.gray80),
  B12: fontStyle(12, 700, colors.gray80),
  B11: fontStyle(11, 700, colors.gray80),
  B10: fontStyle(10, 700, colors.gray60),
  R18: fontStyle(18, 400, colors.gray60),
  R16: fontStyle(16, 400, colors.gray60),
  R14: fontStyle(14, 400, colors.gray60),
  R12: fontStyle(12, 400, colors.gray60),
  R11: fontStyle(11, 400, colors.gray60),
  R10: fontStyle(10, 400, colors.gray60)
};
export var cardShadow = "box-shadow: 0 0 6px 0 rgba(50, 48, 64, 0.33);";
import styled, { css } from 'styled-components';
import { breakpoints, fonts, colors } from "./variables";
var HEIGHT = {
  'extra-small': '36px',
  small: '48px',
  medium: '48px',
  large: '48px',
  auto: 'auto'
};
var WIDTH = {
  'extra-small': '91px',
  small: '204px',
  medium: '217px',
  large: '282px',
  auto: 'auto'
};
var BTN_BASE_SIZE = 'medium';
var BtnHeight = function BtnHeight(size) {
  return size ? "height: ".concat(HEIGHT[size] || HEIGHT[BTN_BASE_SIZE], ";") : 'height: auto;';
};
var BtnWidth = function BtnWidth(size) {
  return size ? "width: ".concat(WIDTH[size] || WIDTH[BTN_BASE_SIZE], ";") : 'width: auto;';
};
var getPadding = function getPadding(props) {
  if (!props.size) {
    return '16px';
  }
  if (props.size === 'auto') {
    if (props.slim) {
      return '8px 16px';
    }
    return '16px';
  }
  return '0 16px';
};
export var DefaultBtn = styled.button.withConfig({
  displayName: "buttons__DefaultBtn",
  componentId: "sc-1gode7t-0"
})(["cursor:pointer;padding:", ";display:flex;align-items:center;justify-content:center;&:disabled{pointer-events:none;opacity:0.5;}", ""], function (props) {
  return getPadding(props);
}, function (props) {
  return css(["", ";", ";", ";", ";"], BtnHeight(props.size), BtnWidth(props.size), props.responsive && "@media (max-width: ".concat(breakpoints.sm, ") {\n        width: 100%;\n      }"), props.fullWidth && "width: 100%;");
});
export var OutlineBtn = styled(DefaultBtn).withConfig({
  displayName: "buttons__OutlineBtn",
  componentId: "sc-1gode7t-1"
})(["", ";color:", ";border:solid 1px ", ";background:transparent;border-radius:4px;&:hover,&:active,&:focus{", " color:", ";& svg path{fill:", ";}}"], fonts.R14, function (props) {
  return props.color;
}, function (props) {
  return props.color;
}, function (props) {
  return props.secondary ? "\n      border-color: ".concat(props.hoverColor || colors.white, ";\n    ") : "\n      background-color: ".concat(props.color || colors.primary, ";\n    ");
}, function (props) {
  return props.hoverColor || colors.white;
}, function (props) {
  return props.hoverColor || colors.white;
});
export var NoStyleBtn = styled.button.withConfig({
  displayName: "buttons__NoStyleBtn",
  componentId: "sc-1gode7t-2"
})(["width:auto;height:auto;margin:0;padding:0;border:0;background:none;color:inherit;flex-shrink:0;line-height:normal;cursor:pointer;&:disabled{pointer-events:none;opacity:0.5;}"]);
export var BtnLink = styled.a.withConfig({
  displayName: "buttons__BtnLink",
  componentId: "sc-1gode7t-3"
})(["display:flex;align-items:center;justify-content:center;width:auto;margin-top:16px;padding:12px 16px;height:48px;", ";text-decoration:none;color:", ";border:solid 1px ", ";background:", ";border-radius:4px;cursor:pointer;line-height:0;&:disabled{pointer-events:none;opacity:0.5;}@media (min-width:", "){&:hover,&:active,&:focus{transform:scale(1.02);}}", ""], fonts.B14, colors.white, colors.primary, colors.primary, breakpoints.md, function (props) {
  return css(["", ";", ";", ";"], props.size && BtnHeight(props.size), props.size && BtnWidth(props.size), props.fullWidth && "width: 100%;");
});
var formatDistanceLocale = {
  lessThanXSeconds: 'now',
  xSeconds: 'now',
  halfAMinute: 'now',
  lessThanXMinutes: 'now',
  xMinutes: {
    one: '1 minute',
    other: '{{count}} minutes',
    strict: '{{count}} min'
  },
  aboutXHours: {
    one: '1 hour',
    other: '{{count}} hours',
    strict: '{{count}} h'
  },
  xHours: {
    one: '1 hour',
    other: '{{count}} hours',
    strict: '{{count}} h'
  },
  xDays: {
    one: '1 day',
    other: '{{count}} days',
    strict: '{{count}} d'
  },
  aboutXWeeks: {
    one: '1 week',
    other: '{{count}} weeks',
    strict: '{{count}} w'
  },
  xWeeks: {
    one: '1 week',
    other: '{{count}} weeks',
    strict: '{{count}} w'
  },
  aboutXMonths: {
    one: '1 month',
    other: '{{count}} months',
    strict: '{{count}} mth'
  },
  xMonths: {
    one: '1 month',
    other: '{{count}} months',
    strict: '{{count}} mth'
  },
  aboutXYears: {
    one: '1 year',
    other: '{{count}} years',
    strict: '{{count}} y'
  },
  xYears: {
    one: '1 year',
    other: '{{count}} years',
    strict: '{{count}} y'
  },
  overXYears: {
    one: '1 year',
    other: '{{count}} years',
    strict: '{{count}} y'
  },
  almostXYears: {
    one: '1 year',
    other: '{{count}} years',
    strict: '{{count}} y'
  }
};
export default function formatDistance(token, count, options) {
  options = options || {};
  var result;
  if (typeof formatDistanceLocale[token] === 'string') {
    result = formatDistanceLocale[token];
  } else if (!options.addSuffix) {
    result = formatDistanceLocale[token].strict.replace('{{count}}', count);
  } else if (count === 1) {
    result = formatDistanceLocale[token].one;
  } else {
    result = formatDistanceLocale[token].other.replace('{{count}}', count);
  }
  if (options.addSuffix) {
    if (options.comparison > 0) {
      return 'in ' + result;
    } else {
      return result + ' ago';
    }
  }
  return result;
}
var FIREBASE = {
  host: 'https://firebaseremoteconfig.googleapis.com',
  credentials: {
    'type': 'service_account',
    'project_id': 'hifire-1184',
    'client_email': 'firebase-adminsdk-hksej@hifire-1184.iam.gserviceaccount.com',
    'auth_uri': 'https://accounts.google.com/o/oauth2/auth',
    'token_uri': 'https://oauth2.googleapis.com/token'
  },
  analytics: {
    apiKey: 'AIzaSyCn2U9Wp71eaexTCruuAnLKz5JWqKDrxxw',
    authDomain: 'hifire-1184.firebaseapp.com',
    databaseURL: 'https://hifire-1184.firebaseio.com',
    projectId: 'hifire-1184',
    storageBucket: 'hifire-1184.appspot.com',
    messagingSenderId: '81122725559',
    appId: '1:81122725559:web:5accce4b8031eec25dc732',
    measurementId: 'G-26W9K5R7SQ'
  }
};
module.exports = {
  FIREBASE: FIREBASE
};
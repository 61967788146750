import { DEFAULT_CARD_WIDTH, TITLE_CHARS_PER_LINE, TITLE_LINE_HEIGHT, MAX_TITLE_LINES, TRACK_CHARS_PER_LINE, TRACK_LINE_HEIGHT, MAX_TRACK_LINES, MARGINS_TOTAL } from "../../components/styles/sparks";
export var AspectRatios = {
  Wide: 16 / 9,
  Square: 1,
  Long: 3 / 4
};
var ASPECT_RATIOS_PER_POST = [AspectRatios.Wide,
// URL
AspectRatios.Square,
// VIDEO
AspectRatios.Wide,
// AUDIO
AspectRatios.Square,
// IMAGE
AspectRatios.Square // TEXT
];

export var predictLayoutHeight = function predictLayoutHeight(spark) {
  var aspectRatio = spark.aspectRatio,
    custom = spark.custom,
    ownerCollectionName = spark.ownerCollectionName;
  var ratio = aspectRatio || AspectRatios.Square;
  var title = custom && custom.title_card;
  var thumbHeight = 1 / ratio * DEFAULT_CARD_WIDTH;
  var titleHeight = title && title.length > 0 ? Math.min(MAX_TITLE_LINES, Math.ceil(title.length / TITLE_CHARS_PER_LINE)) * TITLE_LINE_HEIGHT : 0;
  var trackNameHeight = ownerCollectionName && ownerCollectionName.length > 0 ? Math.min(MAX_TRACK_LINES, Math.ceil(ownerCollectionName.length / TRACK_CHARS_PER_LINE)) * TRACK_LINE_HEIGHT : 0;
  return Math.floor(thumbHeight + titleHeight + trackNameHeight + MARGINS_TOTAL);
};
export var getSparkAspectRatio = function getSparkAspectRatio(spark) {
  var hasSnapshot = spark.hasSnapshot,
    mediaHeight = spark.mediaHeight,
    mediaWidth = spark.mediaWidth,
    postType = spark.postType;
  switch (postType) {
    case 1: // Video
    case 3:
      // Or Image
      var _hasMeasures = hasSnapshot && mediaWidth && mediaHeight;
      return _hasMeasures ? mediaWidth > mediaHeight ? AspectRatios.Wide : mediaWidth < mediaHeight ? AspectRatios.Long : AspectRatios.Square : ASPECT_RATIOS_PER_POST[postType];
    default:
      // Others
      return ASPECT_RATIOS_PER_POST[postType];
  }
};
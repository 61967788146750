import _toConsumableArray from "/drone/src/node_modules/next/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "/drone/src/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
var _createReducer;
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import IO from "../utils/IO";
import Auth from "../utils/Auth";
import { endpoints } from "../utils/config";
import { normalizeTrack } from "../utils/normalizer";
import { createReducer } from "../utils/redux";
import { pushBy } from "../utils/_";
export var types = {
  FETCH: 'tracks/FETCH',
  FETCH_FULFILLED: 'tracks/FETCH_FULFILLED',
  FETCH_REJECTED: 'tracks/FETCH_REJECTED',
  FETCH_PENDING: 'tracks/FETCH_PENDING',
  FETCH_FOLLOWING: 'tracks/FETCH_FOLLOWING',
  FETCH_FOLLOWING_FULFILLED: 'tracks/FETCH_FOLLOWING_FULFILLED',
  FETCH_FOLLOWING_REJECTED: 'tracks/FETCH_FOLLOWING_REJECTED',
  FETCH_FOLLOWING_PENDING: 'tracks/FETCH_FOLLOWING_PENDING',
  FETCH_FEED: 'tracks/FETCH_FEED',
  FETCH_FEED_FULFILLED: 'tracks/FETCH_FEED_FULFILLED',
  FETCH_FEED_REJECTED: 'tracks/FETCH_FEED_REJECTED',
  FETCH_FEED_PENDING: 'tracks/FETCH_FEED_PENDING',
  RESTRICTIONS: 'tracks/RESTRICTIONS',
  FOLLOW: 'tracks/FOLLOW',
  FOLLOW_PRIVATE: 'tracks/FOLLOW_PRIVATE',
  UNFOLLOW: 'tracks/UNFOLLOW',
  CLEAR_TRACKS: 'tracks/CLEAR_TRACKS'
};
var initialByFollowingState = {
  tracks: [],
  page: 0,
  lastPage: false
};
export var initialState = {
  byUrl: {},
  byUser: {},
  byFollowing: {},
  byFeed: {},
  sizes: {},
  fetching: false,
  fetched: false,
  error: null
};
export var getTrackById = function getTrackById(state, id) {
  var trackUrl = Object.keys(state.byUrl).find(function (trackUrl) {
    var track = state.byUrl[trackUrl];
    return track && track.id === id;
  });
  return trackUrl && state.byUrl[trackUrl];
};
var pushSizes = function pushSizes(state, payload) {
  var size = payload.size,
    sizeOf = payload.sizeOf;
  var sizes = _objectSpread({}, state.sizes);
  if (!isNaN(Number(size)) && sizeOf) {
    sizes[sizeOf] = size;
  }
  return sizes;
};
var pushByUser = function pushByUser(state, payload) {
  var data = payload.data,
    userId = payload.userId;
  var incomingTracks = Array.isArray(data) ? data : [data];
  var oldTracks = state.byUser[userId] || [];
  var oldState = _toConsumableArray(oldTracks);
  var tracks = incomingTracks.reduce(function (acc, track) {
    var trackUrl = track.url.toLowerCase();
    return pushBy(acc, trackUrl);
  }, oldState);
  var byUser = _objectSpread(_objectSpread({}, state.byUser), {}, _defineProperty({}, userId, tracks));
  return byUser;
};
var forcePushByUser = function forcePushByUser(state, payload) {
  var data = payload.data,
    userId = payload.userId;
  var incomingTracks = Array.isArray(data) ? data : [data];
  var incomingTrackUrls = incomingTracks.map(function (track) {
    return track.url.toLowerCase();
  });
  var oldTracks = state.byUser[userId] || [];
  var trackUrls = oldTracks.reduce(function (acc, trackUrl) {
    return pushBy(acc, trackUrl);
  }, incomingTrackUrls);
  var byUser = _objectSpread(_objectSpread({}, state.byUser), {}, _defineProperty({}, userId, trackUrls));
  return byUser;
};
var pushByUrl = function pushByUrl(state, payload) {
  var data = payload.data;
  var incomingTracks = Array.isArray(data) ? data : [data];
  var oldTracks = _objectSpread({}, state.byUrl);
  var byUrl = incomingTracks.reduce(function (acc, track) {
    var trackUrl = track.url.toLowerCase();
    return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, trackUrl, normalizeTrack(track)));
  }, oldTracks);
  return byUrl;
};
var pushByFeed = function pushByFeed(state, payload) {
  var data = payload.data;
  var incomingTracks = Array.isArray(data) ? data : [data];
  var byFeed = incomingTracks.reduce(function (acc, track) {
    var trackUrl = track.url.toLowerCase();
    return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, trackUrl, track));
  }, {});
  return byFeed;
};
var pushByFollowing = function pushByFollowing(state, payload) {
  var data = payload.data,
    userId = payload.userId,
    page = payload.page,
    rows = payload.rows;
  var incomingTracks = Array.isArray(data) ? data : [data];
  var oldState = _objectSpread(_objectSpread({}, initialByFollowingState), state.byFollowing[userId]);
  var oldTracks = _toConsumableArray(oldState.tracks);
  var tracks = incomingTracks.reduce(function (acc, track) {
    var trackUrl = track.url.toLowerCase();
    return pushBy(acc, trackUrl);
  }, oldTracks);
  var newPage = page > oldState.page ? page : oldState.page;
  var lastPage = incomingTracks.length < rows;
  var byFollowing = _objectSpread(_objectSpread({}, state.byFollowing), {}, _defineProperty({}, userId, _objectSpread(_objectSpread({}, oldState), {}, {
    tracks: tracks,
    page: newPage,
    lastPage: lastPage
  })));
  return byFollowing;
};

// REDUCERS
var pending = function pending(state, action) {
  var userId = action.payload;
  var oldUser = state.byUser[userId];
  return _objectSpread(_objectSpread({}, state), {}, {
    fetching: true,
    fetched: false,
    error: null,
    byUser: _objectSpread(_objectSpread({}, state.byUser), {}, _defineProperty({}, userId, oldUser || []))
  });
};
var fulfilled = function fulfilled(state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    sizes: pushSizes(state, action.payload),
    byUrl: pushByUrl(state, action.payload),
    byUser: pushByUser(state, action.payload),
    fetching: false,
    fetched: true
  });
};
var fulfilledFollowing = function fulfilledFollowing(state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    byUrl: pushByUrl(state, action.payload),
    byFollowing: pushByFollowing(state, action.payload),
    fetching: false,
    fetched: true
  });
};
var fulfilledFeed = function fulfilledFeed(state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    byUser: forcePushByUser(state, action.payload),
    byFeed: pushByFeed(state, action.payload),
    fetching: false,
    fetched: true
  });
};
var rejected = function rejected(state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    error: action.payload,
    fetching: false,
    fetched: false
  });
};
var clearTracks = function clearTracks(state, action) {
  var userId = action.payload.userId;
  return _objectSpread(_objectSpread({}, state), {}, {
    byUser: _objectSpread(_objectSpread({}, state.byUser), {}, _defineProperty({}, userId, [])),
    sizes: _objectSpread(_objectSpread({}, state.sizes), {}, _defineProperty({}, userId, 0)),
    fetching: false,
    fetched: false
  });
};
var restrictions = function restrictions(state, action) {
  var data = action.payload.data;
  var oldTrack = getTrackById(state, data.id);
  var newTrack = _objectSpread(_objectSpread({}, oldTrack), {}, {
    restrictions: data.restrictions
  });
  return _objectSpread(_objectSpread({}, state), {}, {
    byUrl: _objectSpread(_objectSpread({}, state.byUrl), {}, _defineProperty({}, oldTrack.url, newTrack))
  });
};
var follow = function follow(state, action) {
  var _action$payload = action.payload,
    trackUrl = _action$payload.trackUrl,
    userId = _action$payload.userId;
  var track = _objectSpread({}, state.byUrl[trackUrl]);
  track.isFollowing = true;
  track.following = true;
  track.followersSize = track.followersSize + 1;
  if (userId) {
    var _userFollowingState = state.byFollowing[userId] || {};
    var _userFollowingTracks = _userFollowingState.tracks || [];
    state.byFollowing = _objectSpread(_objectSpread({}, state.byFollowing), {}, _defineProperty({}, userId, _objectSpread(_objectSpread({}, _userFollowingState), {}, {
      tracks: pushBy(_userFollowingTracks, trackUrl)
    })));
  }
  track.followStatus = 1;
  return _objectSpread(_objectSpread({}, state), {}, {
    byUrl: _objectSpread(_objectSpread({}, state.byUrl), {}, _defineProperty({}, trackUrl, track))
  });
};
var followPrivate = function followPrivate(state, action) {
  var trackUrl = action.payload.trackUrl;
  return _objectSpread(_objectSpread({}, state), {}, {
    byUrl: _objectSpread(_objectSpread({}, state.byUrl), {}, _defineProperty({}, trackUrl, _objectSpread(_objectSpread({}, state.byUrl[trackUrl]), {}, {
      followStatus: 2
    })))
  });
};
var unfollow = function unfollow(state, action) {
  var _action$payload2 = action.payload,
    trackUrl = _action$payload2.trackUrl,
    userId = _action$payload2.userId,
    oldStatus = _action$payload2.oldStatus;
  var track = _objectSpread({}, state.byUrl[trackUrl]);
  track.isFollowing = false;
  track.following = false;
  if (oldStatus === 1) {
    track.followersSize = track.followersSize > 0 ? track.followersSize - 1 : 0;
    if (userId) {
      var _userFollowingState2 = state.byFollowing[userId] || {};
      var _userFollowingTracks2 = _userFollowingState2.tracks || [];
      state.byFollowing = _objectSpread(_objectSpread({}, state.byFollowing), {}, _defineProperty({}, userId, _objectSpread(_objectSpread({}, _userFollowingState2), {}, {
        tracks: _userFollowingTracks2.filter(function (url) {
          return trackUrl !== url;
        })
      })));
    }
  }
  track.followStatus = 0;
  return _objectSpread(_objectSpread({}, state), {}, {
    byUrl: _objectSpread(_objectSpread({}, state.byUrl), {}, _defineProperty({}, trackUrl, track))
  });
};
export var reducer = createReducer(initialState, (_createReducer = {}, _defineProperty(_createReducer, types.FETCH_PENDING, pending), _defineProperty(_createReducer, types.FETCH_FULFILLED, fulfilled), _defineProperty(_createReducer, types.FETCH_REJECTED, rejected), _defineProperty(_createReducer, types.FETCH_FOLLOWING_PENDING, pending), _defineProperty(_createReducer, types.FETCH_FOLLOWING_FULFILLED, fulfilledFollowing), _defineProperty(_createReducer, types.FETCH_FOLLOWING_REJECTED, rejected), _defineProperty(_createReducer, types.FETCH_FEED_PENDING, pending), _defineProperty(_createReducer, types.FETCH_FEED_FULFILLED, fulfilledFeed), _defineProperty(_createReducer, types.FETCH_FEED_REJECTED, rejected), _defineProperty(_createReducer, types.RESTRICTIONS, restrictions), _defineProperty(_createReducer, types.FOLLOW, follow), _defineProperty(_createReducer, types.FOLLOW_PRIVATE, followPrivate), _defineProperty(_createReducer, types.UNFOLLOW, unfollow), _defineProperty(_createReducer, types.CLEAR_TRACKS, clearTracks), _createReducer));

// ACTIONS
export var fetchTrack = function fetchTrack(url, req) {
  var auth = IO.auth(req);
  return IO.get(endpoints.APIv1 + "/collections/".concat(auth ? 'secureUrl' : 'url', "/").concat(url), null, auth).then(function (res) {
    return res.data.data || res.data;
  });
};
export var getTrack = function getTrack(url, req) {
  return function (dispatch) {
    var prom = fetchTrack(url, req);
    if (dispatch) {
      dispatch({
        type: types.FETCH_PENDING
      });
      prom.then(function (data) {
        dispatch({
          type: types.FETCH_FULFILLED,
          payload: {
            data: data
          }
        });
      }).catch(function (err) {
        dispatch({
          type: types.FETCH_REJECTED,
          payload: err.response || err
        });
      });
    }
    return prom;
  };
};
export var getTracksRequest = function getTracksRequest(userId, rows, page, state) {
  var auth = IO.auth();
  var params = "?page=".concat(page, "&rows=").concat(rows);
  var path = "/users/".concat(userId, "/collections/owned") + params;
  return IO.get(endpoints.APIv1 + path, null, auth);
};
export var getUserTracks = function getUserTracks(userId) {
  var rows = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 20;
  var page = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  return function (dispatch, getState) {
    dispatch({
      type: types.FETCH_PENDING,
      payload: userId
    });
    var request = getTracksRequest(userId, rows, page, getState());
    return request.then(function (res) {
      dispatch({
        type: types.FETCH_FULFILLED,
        payload: {
          userId: userId,
          data: res.data.data
        }
      });
      return res.data.size;
    }).catch(function (err) {
      dispatch({
        type: types.FETCH_REJECTED,
        payload: err.response || err
      });
    });
  };
};
export var getMyFollowedTracksRequest = function getMyFollowedTracksRequest(rows, page, state) {
  var auth = IO.auth();
  var params = {
    page: page,
    rows: rows,
    countNews: true,
    countLikes: true,
    countFollowers: true
  };
  var path = '/collections/following';
  return IO.get(endpoints.APIv1 + path, params, auth);
};
export var getTracksFollowedRequest = function getTracksFollowedRequest(userId, rows, page, state) {
  var auth = IO.auth();
  var params = "?page=".concat(page, "&rows=").concat(rows);
  var path = "/users/".concat(userId, "/collections/followed") + params;
  return IO.get(endpoints.APIv1 + path, null, auth);
};
export var getTracksFollowedByUser = function getTracksFollowedByUser(userId) {
  var rows = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 20;
  var page = arguments.length > 2 ? arguments[2] : undefined;
  var isMe = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  return function (dispatch, getState) {
    dispatch({
      type: types.FETCH_FOLLOWING_PENDING,
      payload: userId
    });
    var state = getState();
    var request = isMe ? getMyFollowedTracksRequest(rows, page, state) : getTracksFollowedRequest(userId, rows, page, state);
    return request.then(function (res) {
      var response = res && res.data || {};
      var _response$data = response.data,
        data = _response$data === void 0 ? [] : _response$data;
      dispatch({
        type: types.FETCH_FOLLOWING_FULFILLED,
        payload: {
          userId: userId,
          data: data,
          rows: rows,
          page: page
        }
      });
      return res.data.data;
    }).catch(function (err) {
      dispatch({
        type: types.FETCH_FOLLOWING_REJECTED,
        payload: err.response || err
      });
    });
  };
};
export var getTrendingTracks = function getTrendingTracks() {
  var rows = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 9;
  var page = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var req = arguments.length > 2 ? arguments[2] : undefined;
  return function (dispatch) {
    var auth = IO.auth(req);
    dispatch({
      type: types.FETCH_PENDING,
      payload: 'trending'
    });
    return IO.get(endpoints.APIv2 + "/feed/collections/recommendations?page=".concat(page, "&rows=").concat(rows), null, auth).then(function (res) {
      dispatch({
        type: types.FETCH_FULFILLED,
        payload: {
          userId: 'trending',
          page: page,
          rows: rows,
          data: res.data.data,
          size: res.data.size,
          sizeOf: 'trending'
        }
      });
    }).catch(function (err) {
      dispatch({
        type: types.FETCH_REJECTED,
        payload: err.response || err
      });
    });
  };
};
export var getNewTracks = function getNewTracks(req) {
  return function (dispatch) {
    var auth = IO.auth(req);
    dispatch({
      type: types.FETCH_FEED_PENDING,
      payload: 'feed'
    });
    return IO.get(endpoints.APIv2 + '/feed/collections', null, auth).then(function (res) {
      var normalizedData = res.data.map(normalizeTrack);
      dispatch({
        type: types.FETCH_FEED_FULFILLED,
        payload: {
          userId: 'feed',
          data: normalizedData
        }
      });
    }).catch(function (err) {
      dispatch({
        type: types.FETCH_FEED_REJECTED,
        payload: err.response || err
      });
    });
  };
};
export var handleSparkRestrictions = function handleSparkRestrictions(data) {
  return function (dispatch) {
    dispatch({
      type: types.RESTRICTIONS,
      payload: {
        data: data
      }
    });
  };
};
export var getAllowedTracks = function getAllowedTracks() {
  return IO.get(endpoints.APIv1 + '/collections/allowed', null, {
    authenticated: true
  }).then(function (res) {
    return res.data.data.map(normalizeTrack);
  }).catch(console.error);
};
export var getPaidTracks = function getPaidTracks() {
  return IO.get(endpoints.APIv1 + '/collections/ownedPaid', null, {
    authenticated: true
  }).then(function (res) {
    return res.data.data.map(normalizeTrack);
  }).catch(console.error);
};
export var findTrack = function findTrack(trackUrl, store) {
  var state = store.getState().tracks;
  return state.byUrl[trackUrl];
};
export var toggleFollow = function toggleFollow(trackId, trackUrl) {
  var oldFollowStatus = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  return function (dispatch, getState) {
    var _getState = getState(),
      tracks = _getState.tracks;
    var track = tracks.byUrl && tracks.byUrl[trackUrl] || {};
    var request = [{
      method: 'put',
      path: 'follower',
      newStatus: track.isPublic ? 1 : 2
    }, {
      method: 'put',
      path: 'unfollow',
      newStatus: 0
    }, {
      method: 'put',
      path: 'unfollow',
      newStatus: 0
    }, {
      method: 'post',
      path: 'invites/accept',
      newStatus: 1
    }][oldFollowStatus];
    var me = Auth.getUser() || {};
    return IO[request.method](endpoints.APIv1 + "/collections/".concat(trackId, "/").concat(request.path), null, {
      authenticated: true
    }).then(function () {
      var action = [types.UNFOLLOW, types.FOLLOW, types.FOLLOW_PRIVATE][request.newStatus];
      dispatch({
        type: action,
        payload: {
          trackUrl: trackUrl,
          userId: me.id,
          oldStatus: oldFollowStatus
        }
      });
    });
  };
};
export var getSearchRequest = function getSearchRequest(searchTerm, rows, page) {
  var triesLeft = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
  var auth = IO.auth();
  return new Promise(function (resolve, reject) {
    IO.get(endpoints.APIv1 + "/collections/search?page=".concat(page, "&rows=").concat(rows, "&q=").concat(encodeURIComponent(searchTerm)), null, auth).then(function (res) {
      return resolve(res);
    }).catch(function (err) {
      if (triesLeft > 0) {
        triesLeft--;
        getSearchRequest(searchTerm, rows, page, triesLeft).then(function (res) {
          return resolve(res);
        }).catch(function (err) {
          return reject(err);
        });
      } else {
        reject(err);
      }
    });
  });
};
export var searchTracks = function searchTracks(searchTerm) {
  var rows = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 9;
  var page = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  return function (dispatch) {
    var searchId = 'search';
    dispatch({
      type: types.FETCH_PENDING,
      payload: searchId
    });
    return getSearchRequest(searchTerm, rows, page, 3).then(function (res) {
      dispatch({
        type: types.FETCH_FULFILLED,
        payload: {
          page: page,
          rows: rows,
          userId: searchId,
          data: res.data.data,
          size: res.data.size,
          sizeOf: searchId
        }
      });
    }).catch(function (err) {
      dispatch({
        type: types.FETCH_REJECTED,
        payload: err.response || err
      });
    });
  };
};
export var clearTracksByUser = function clearTracksByUser(userId) {
  return function (dispatch) {
    dispatch({
      type: types.CLEAR_TRACKS,
      payload: {
        userId: userId
      }
    });
  };
};
export var getTrackWebinar = function getTrackWebinar(trackId) {
  return IO.get("".concat(endpoints.APIv1, "/webinar/collection/").concat(trackId), null, {
    authenticated: true
  });
};
export var deleteTrackNews = function deleteTrackNews(trackId, newsIds) {
  var blockAuthors = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  return IO.post("".concat(endpoints.APIv1, "/collections/").concat(trackId, "/bulkNewsDeletion"), {
    newsIds: newsIds,
    blockAuthors: blockAuthors
  }, {
    authenticated: true
  });
};
import buildFormatLongFn from 'date-fns/locale/_lib/buildFormatLongFn/index';
var dateFormats = {
  detailed: 'MM/dd/yyyy',
  full: 'EEEE, MMMM do, y',
  long: 'MMMM do, y',
  medium: 'MMM d, y',
  short: 'MM/dd/yyyy'
};
var timeFormats = {
  detailed: 'hh:mm:ss a',
  full: 'h:mm a',
  long: 'h:mm a',
  medium: 'h:mm a',
  short: 'h:mm a'
};
var dateTimeFormats = {
  detailed: "".concat(dateFormats.detailed, " ").concat(timeFormats.detailed),
  full: "".concat(dateFormats.full, " 'at' ").concat(timeFormats.full),
  long: "".concat(dateFormats.long, " 'at' ").concat(timeFormats.long),
  medium: "".concat(dateFormats.medium, " 'at' ").concat(timeFormats.medium),
  short: "".concat(dateFormats.short, ", ").concat(timeFormats.short)
};
var formatLong = {
  date: buildFormatLongFn({
    formats: dateFormats,
    defaultWidth: 'long'
  }),
  time: buildFormatLongFn({
    formats: timeFormats,
    defaultWidth: 'long'
  }),
  dateTime: buildFormatLongFn({
    formats: dateTimeFormats,
    defaultWidth: 'long'
  })
};
export default formatLong;
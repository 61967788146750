import buildFormatLongFn from 'date-fns/locale/_lib/buildFormatLongFn/index';
var dateFormats = {
  detailed: 'dd/MM/yyyy',
  full: "EEEE, d 'de' MMMM 'de' y",
  long: "d 'de' MMMM 'de' y",
  medium: 'd MMM y',
  short: 'dd/MM/y'
};
var timeFormats = {
  detailed: 'HH:mm:ss',
  full: 'H:mm',
  long: 'H:mm',
  medium: 'H:mm',
  short: 'H:mm'
};
var dateTimeFormats = {
  detailed: "".concat(dateFormats.detailed, " ").concat(timeFormats.detailed),
  full: "".concat(dateFormats.full, " 'a las' ").concat(timeFormats.full),
  long: "".concat(dateFormats.long, " 'a las' ").concat(timeFormats.long),
  medium: "".concat(dateFormats.medium, " 'a las' ").concat(timeFormats.medium),
  short: "".concat(dateFormats.short, ", ").concat(timeFormats.short)
};
var formatLong = {
  date: buildFormatLongFn({
    formats: dateFormats,
    defaultWidth: 'long'
  }),
  time: buildFormatLongFn({
    formats: timeFormats,
    defaultWidth: 'long'
  }),
  dateTime: buildFormatLongFn({
    formats: dateTimeFormats,
    defaultWidth: 'long'
  })
};
export default formatLong;
import _defineProperty from "/drone/src/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
var _createReducer;
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import IO from "../utils/IO";
import Auth from "../utils/Auth";
import { endpoints } from "../utils/config";
import { createReducer } from "../utils/redux";
export var initialState = {
  byUser: {},
  associableToDonation: [],
  fetching: false,
  fetched: false,
  fetchingTracks: false,
  fetchedTracks: false,
  creating: false,
  updating: false,
  deleting: false,
  error: null
};
export var types = {
  FETCH: 'donation/FETCH',
  FETCH_FULFILLED: 'donation/FETCH_FULFILLED',
  FETCH_REJECTED: 'donation/FETCH_REJECTED',
  FETCH_PENDING: 'donation/FETCH_PENDING',
  SET_INFO: 'donation/SET_INFO'
};

// REDUCERS
var pending = function pending(state, action) {
  var _objectSpread2;
  var _action$payload = action.payload,
    userId = _action$payload.userId,
    _action$payload$fetch = _action$payload.fetching,
    fetching = _action$payload$fetch === void 0 ? 'fetching' : _action$payload$fetch,
    _action$payload$fetch2 = _action$payload.fetched,
    fetched = _action$payload$fetch2 === void 0 ? 'fetched' : _action$payload$fetch2;
  var output = _objectSpread(_objectSpread({}, state), {}, (_objectSpread2 = {}, _defineProperty(_objectSpread2, fetching, true), _defineProperty(_objectSpread2, fetched, false), _defineProperty(_objectSpread2, "error", null), _objectSpread2));
  if (userId && !state.byUser[userId]) output.byUser[userId] = [];
  return output;
};
var fulfilled = function fulfilled(state, action) {
  var _objectSpread3;
  var _action$payload2 = action.payload,
    userId = _action$payload2.userId,
    data = _action$payload2.data,
    associableToDonation = _action$payload2.associableToDonation,
    _action$payload2$fetc = _action$payload2.fetching,
    fetching = _action$payload2$fetc === void 0 ? 'fetching' : _action$payload2$fetc,
    _action$payload2$fetc2 = _action$payload2.fetched,
    fetched = _action$payload2$fetc2 === void 0 ? 'fetched' : _action$payload2$fetc2;
  var output = _objectSpread(_objectSpread({}, state), {}, (_objectSpread3 = {}, _defineProperty(_objectSpread3, fetching, false), _defineProperty(_objectSpread3, fetched, true), _objectSpread3));
  if (userId) output.byUser[userId] = data;
  if (associableToDonation) output.associableToDonation = associableToDonation;
  return output;
};
var rejected = function rejected(state, action) {
  var _objectSpread4;
  var _action$payload3 = action.payload,
    error = _action$payload3.error,
    _action$payload3$fetc = _action$payload3.fetching,
    fetching = _action$payload3$fetc === void 0 ? 'fetching' : _action$payload3$fetc,
    _action$payload3$fetc2 = _action$payload3.fetched,
    fetched = _action$payload3$fetc2 === void 0 ? 'fetched' : _action$payload3$fetc2;
  return _objectSpread(_objectSpread({}, state), {}, (_objectSpread4 = {}, _defineProperty(_objectSpread4, fetching, false), _defineProperty(_objectSpread4, fetched, false), _defineProperty(_objectSpread4, "error", error), _objectSpread4));
};
var setInfoOnState = function setInfoOnState(state, action) {
  return _objectSpread(_objectSpread({}, state), action.payload);
};
export var reducer = createReducer(initialState, (_createReducer = {}, _defineProperty(_createReducer, types.FETCH_PENDING, pending), _defineProperty(_createReducer, types.FETCH_FULFILLED, fulfilled), _defineProperty(_createReducer, types.FETCH_REJECTED, rejected), _defineProperty(_createReducer, types.SET_INFO, setInfoOnState), _createReducer));

// ACTIONS
var getUserOffers = function getUserOffers(userId) {
  return IO.get("".concat(endpoints.APIv1, "/sales/users/").concat(userId)).then(function (res) {
    return res.data;
  });
};
export var getOffers = function getOffers(userId) {
  return function (dispatch) {
    dispatch({
      type: types.FETCH_PENDING,
      payload: {
        userId: userId
      }
    });
    return getUserOffers(userId).then(function (res) {
      dispatch({
        type: types.FETCH_FULFILLED,
        payload: {
          userId: userId,
          data: res.offers
        }
      });
      return res.offers;
    }).catch(function (err) {
      console.error(err);
      dispatch({
        type: types.FETCH_REJECTED,
        payload: {
          error: err.response || err
        }
      });
    });
  };
};
export var setInfo = function setInfo(key, value) {
  return function (dispatch) {
    dispatch({
      type: types.SET_INFO,
      payload: _defineProperty({}, key, value)
    });
  };
};
export var getAssociableToDonation = function getAssociableToDonation() {
  return function (dispatch) {
    dispatch({
      type: types.FETCH_PENDING,
      payload: {
        fetching: 'fetchingTracks',
        fetched: 'fetchedTracks'
      }
    });
    return IO.get("".concat(endpoints.APIv1, "/collections/associableToDonation"), null, {
      authenticated: true
    }).then(function (res) {
      dispatch({
        type: types.FETCH_FULFILLED,
        payload: {
          associableToDonation: res.data.data,
          fetching: 'fetchingTracks',
          fetched: 'fetchedTracks'
        }
      });
    }).catch(function (err) {
      console.error(err);
      dispatch({
        type: types.FETCH_REJECTED,
        payload: {
          error: err.response || err,
          fetching: 'fetchingTracks',
          fetched: 'fetchedTracks'
        }
      });
    });
  };
};
export var createDonation = function createDonation(donation) {
  return function (dispatch) {
    setInfo('creating', true)(dispatch);
    var headers = {
      'Content-Type': 'application/json;charset=UTF-8'
    };
    var body = {
      name: donation.name,
      description: donation.description,
      price: donation.price,
      collections: donation.collections
    };
    return IO.post(endpoints.APIv2 + "/donations", body, {
      authenticated: true,
      headers: headers
    }).then(function (res) {
      var user = Auth.getUser();
      return getOffers(user.id)(dispatch);
    }).catch(function (err) {
      console.error(err);
      dispatch({
        type: types.FETCH_REJECTED,
        payload: {
          error: err.response || err
        }
      });
      throw err;
    }).finally(function () {
      setInfo('creating', false)(dispatch);
    });
  };
};
export var updateDonation = function updateDonation(donation) {
  return function (dispatch) {
    setInfo('updating', true)(dispatch);
    var headers = {
      'Content-Type': 'application/json;charset=UTF-8'
    };
    var donationId = donation.id;
    var body = {
      name: donation.name,
      description: donation.description,
      price: donation.price,
      collections: donation.collections
    };
    return IO.put(endpoints.APIv2 + "/donations/".concat(donationId), body, {
      authenticated: true,
      headers: headers
    }).then(function (res) {
      var user = Auth.getUser();
      return getOffers(user.id)(dispatch);
    }).catch(function (err) {
      console.error(err);
      dispatch({
        type: types.FETCH_REJECTED,
        payload: {
          error: err.response || err
        }
      });
      throw err;
    }).finally(function () {
      setInfo('updating', false)(dispatch);
    });
  };
};
export var deleteDonation = function deleteDonation(donationId) {
  return function (dispatch) {
    setInfo('deleting', true)(dispatch);
    return IO.delete(endpoints.APIv2 + "/donations/".concat(donationId), null, {
      authenticated: true
    }).then(function (res) {
      var user = Auth.getUser();
      return getOffers(user.id)(dispatch);
    }).catch(function (err) {
      console.error(err);
      dispatch({
        type: types.FETCH_REJECTED,
        payload: {
          error: err.response || err
        }
      });
      throw err;
    }).finally(function () {
      setInfo('deleting', false)(dispatch);
    });
  };
};
export var getHotmartCheckoutUrl = function getHotmartCheckoutUrl(checkoutUrl) {
  return IO.get("".concat(endpoints.APIv1, "/wallet/checkout/physicalPurchase"), {
    checkoutUrl: checkoutUrl
  }, {
    authenticated: true
  }).then(function (res) {
    return res.data;
  });
};
export var allowedToCreateSubscription = function allowedToCreateSubscription() {
  return IO.get("".concat(endpoints.APIv2, "/donations/allowed"), null, {
    authenticated: true
  }).then(function (res) {
    return res.data;
  });
};
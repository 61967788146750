import { endpoints } from "../config";
export var getCover = function getCover(userId, size) {
  if (!userId) return null;
  var path = "".concat(endpoints.APIv1, "/users/").concat(userId, "/cover");
  if (size || size === 0) path = path + "?size=".concat(size);
  return path;
};
export var getImage = function getImage(userId) {
  var size = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  if (!userId) return null;
  var dimensions = size > 0 ? "dimensions=".concat(size, "x").concat(size) : '';
  return "".concat(endpoints.APIv1, "/users/").concat(userId, "/image?").concat(dimensions);
};
export var getBanner = function getBanner(userId, bannerId) {
  if (!userId) return null;
  return "".concat(endpoints.APIv1, "/users/").concat(userId, "/banners/").concat(bannerId);
};